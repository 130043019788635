import axios from "axios";
import BASE_URL from "../config/host";

export const postData = async (url, data,contentType='application/json',setUploadPercentage=() => {}) => {
  try {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'content-type': contentType
      },
      onUploadProgress: progress => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
        const exactUploadPercentage = (uploadPercentage - 5) < 0 ? 0 : (uploadPercentage - 5)

        setUploadPercentage(Math.ceil(exactUploadPercentage));
        // console.log('uploadPercentage',uploadPercentage)
        // console.log("total size in MB ==> ", totalSizeInMB);
        // console.log("uploaded size in MB ==> ", loadedSizeInMB);
      },
    });

    const res = await instance.post(url, data);
    return res.data;
  } catch (error) {
    return { status: 400, message: error.message };
  }
};
