import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";

const AddEditAdminModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setEmail(editData.email);
      setPhone(editData.phone);
      setLastName(editData.lastName);
      // setPassword(editData.password);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setEmail("");
    setPhone("");
    setLastName("");
    setPassword("");
    setDisabled(false);
    setEditData({});
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(`user/${editData?._id ? "edit" : "add"}`, {
      // table_name: 'user',
      password: password,
      name: name,
      type: "admin",
      email: email,
      email_verified: 1,
      phone: phone,
      id: editData?._id || null,
      ordering: editData?._id ? editData.ordering : list.length,
    });

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
      modalSize={"xl"}
    >
      <div className="relative w-full">
          <div className="relative w-full mb-3">
            <Input
              label={"Name"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Add Admin's Name"}
              inputValue={name}
              setInput={setName}
            />
          </div>
        <div className="grid sm:grid-cols-2 gap-4">
          <div className="relative w-full mb-3">
            <Input
              label={"Phone"}
              labelClasses={"!text-xs"}
              inputType={"number"}
              inputPlaceholder={"Add Phone No."}
              inputValue={phone}
              setInput={setPhone}
            />
          </div>
          <div className="relative w-full mb-3">
            <Input
              label={"Email"}
              labelClasses={"!text-xs"}
              inputType={"email"}
              inputPlaceholder={"Add Email"}
              inputValue={email}
              setInput={setEmail}
            />
          </div>
        </div>
        <div className="relative w-full mb-3">
            <Input
              label={"Password"}
              labelClasses={"!text-xs"}
              inputType={"password"}
              inputPlaceholder={"Add Password"}
              inputValue={password}
              setInput={setPassword}
            />
          </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditAdminModal;
