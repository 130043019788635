import React,{useEffect,useState,useRef} from 'react';
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import Image from '../components/elements/Image';
import { Link } from 'react-router-dom';
import Button from '../components/form/Button';
import LogoutPopup from '../components/LogoutPopup';

import { useSelector } from 'react-redux';
// import { doesFileExist } from '../helpers';


const UserDropdown = ({ xPlacement, ...props }) => { 
  // const role =  localStorage.getItem("role"); 
  const user = useSelector(state => state.auth?.user);
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  

  const [displayImage,setDisplayImage]= useState('');
  const [displayName,setDisplayName]= useState('');
  const [enabledLogoutPopup,setenabledLogoutPopup] = useState(false);


  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
 
  let dropdownData = [
    {_id:1, label: 'Profile', icon:'fa-circle-user', link:'/profile', type:'link', standout:false},
    {_id:10, label: 'Logout', icon:'fa-arrow-right-from-arc', link:'/', type:'button', standout:false}
  ];

  useEffect(() => {
    setDisplayName(user?.name);
    setDisplayImage(user?.image)
  }, [user]);   

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div className="relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200">
                <div className="w-8 h-8 min-w-[32px] overflow-hidden rounded-full">
                  {
                    user?._id &&
                    <Image 
                      src={displayImage} 
                      alt={'Admin'} 
                      width={'100%'} 
                      height={'100%'} 
                      effect={'blur'} 
                      classes={'object-contain'}
                      id={'headerImg'}
                    />
                  }
                </div>
                <div className="text-sm font-semibold text-slate-500 mx-2">{displayName}</div>
                <div className="text-xs text-slate-400">
                  <i className={"fa-light fa-fw " + (open?'fa-chevron-up':'fa-chevron-down')}></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75" leaveFrom="opacity-100" leaveTo="opacity-0" className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-48 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0">
                <div className="py-1 max-h-80 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
                  {dropdownData.map((item,index) => (
                    <Menu.Item key={index} as="div">
                      {item.type === 'link'?
                      <Link to={item.link} className="flex items-center py-2 px-3 gap-3 text-slate-500 font-medium hover:bg-teal-500 hover:text-white transition-all duration-200">
                        <div className="w-5 h-5 flex items-center justify-center text-base">
                          <i className={"fa-regular fa-fw " + (item.icon)}></i>
                        </div>
                        <div className="text-xs">{item.label}</div>
                      </Link>:''
                      }
                      {item.type === 'button'?
                      <Button 
                      buttonLabel={'Logout'} 
                      buttonClasses={'!w-full !flex !items-center !justify-start !py-2 !px-3 !gap-3 !text-rose-500 !rounded-none !text-base !font-medium !bg-white hover:!bg-teal-500 hover:!text-white !transition-all !duration-200'} 
                      buttonType={'button'} 
                      buttonIcon={'fa-regular fa-arrow-right-from-arc'} 
                      buttonIconPosition={'left'} 
                      buttonHasLink={false}
                      buttonLabelClasses={'!text-xs !font-semibold'}
                      functions={() => setenabledLogoutPopup(true)}
                      />:''
                      }
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {
        enabledLogoutPopup &&
        <LogoutPopup
        isOpen={enabledLogoutPopup}
        setIsOpen={(val) => setenabledLogoutPopup(val)}
        />
      }
    </>
  );
};

export default UserDropdown;