import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import toastr from "toastr"
import AddEditLayout from "../layouts/AddEditLayout"
import Input from "../components/form/Input"
import { postData } from "../services/api"

const AddEditCategory = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [disabled, setDisabled] = useState(false)
  const [name, setName] = useState('')

  useEffect(() => {
    if (id) {
      const loadDetails = async () => {
        const res = await postData('category/details', {
          id
        })

        if (res.status === 200 && res.data) {
          setName(res.data?.name || '')
        }
      }

      loadDetails()
    }
  }, [ id ])
  

  const onSubmit = async () => {
    setDisabled(true)
    const res = await postData(`category/${id ? 'edit'  : 'add'}`, { 
      id: id || null,
      name 
    })

    if (res.status === 200) {
      toastr.success(res.message)
      navigate('/categories')
    } else {
      toastr.error(res.message)
    }

    setDisabled(false)
  }

  return (
    <AddEditLayout
      title={id ? 'Edit Category' : "Add Category"}
      onSubmit={onSubmit}
      submitting={disabled}
      disabled={disabled}
    >
      <div className="relative w-full">
        <Input 
          label={'Category Name'} 
          labelClasses={'!text-xs'} 
          inputType={'text'}
          inputPlaceholder={'Enter Category Name'}
          inputValue={name}  
          setInput={setName} 
        />        
      </div>
    </AddEditLayout>
  )
}

export default AddEditCategory