import { useState, useEffect, useRef } from "react";
import Input from "../form/Input";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMceInit, tinymceApiKey } from "../../helpers";


import { postData } from "../../services/api";

import toastr from "toastr";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Image from "../elements/Image";
import ButtonFile from "../form/ButtonFile";
import { API_URL } from "../../config/host";
import TabContainer from "../elements/TabContainer";
import Button from "../form/Button";
import Textarea from "../form/Textarea";


const EditPages = ({
  title = "",
  show = false,
  hasImageField = true,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) => {
  const editorRef = useRef(null);
  const [disabled, setDisabled] = useState();

  const [description, setDescription] = useState({});
  const [name, setName] = useState({});
  const [subTitle, setSubTitle] = useState({});
  const [image, setImage] = useState(null);
  const [alias, setAlias] = useState("");
  const [langs, setLangs] = useState([])
  const [selectedLangTab, setSelectedLangTab] = useState('en')
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);


  useEffect(() => {
    if (editData?._id) {
      if (editData.name) {
        typeof editData.name === "string" ? setName({ en: editData.name }) : setName(editData.name);
      }
      if (editData.sub_title) {
        typeof editData.sub_title === "string" ? setSubTitle({ en: editData.sub_title }) : setSubTitle(editData.sub_title);
      }
      if (editData.description) {
        typeof editData.description === "string" ? setDescription({ en: editData.description }) : setDescription(editData.description);
      }
      setImage(editData.image);
      setAlias(editData.alias);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName({});
    setDescription("");
    setDisabled(false);
    setEditData({});
    setSelectedLangTab('en')
  };
  const onImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png","image/svg+xml"];


      if (!allowedImageTypes.includes(file.type)) {
        toastr.error("File is not a valid image.");
        return;
      }
      setImage(file);
    }
  };

  const onSubmit = async () => {
    setDisabled(true);
    let imageId = undefined;
    const formData = new FormData();

    const docs = {
      id: editData?._id || null,
      name: name,
      sub_title: subTitle,
      description: description,
      alias: alias,
      image_id: imageId,
      ordering: editData?._id ? editData.ordering : list.length,
      hasLang: true,
    }

    const formDocs = JSON.stringify(docs)


    if (image instanceof File) {
      formData.append("file", image);
      // const imgRes = await postData(
      //   "file/upload",
      //   formData,
      //   "multipart/form-data"
      // );
      // if (imgRes.status === 200) {
      //   imageId = imgRes.data?._id;
      // } else {
      //   setDisabled(false);
      //   toastr.error(imgRes.message);
      // }
    }

    formData.append("document", formDocs);

    const res = await postData(`page/edit`, formData, "multipart/form-data");
    if (res.status === 200) {
      const listRes = await postData('page/list', {
        deleted_at: null,
        sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 } || "",
      });
      if (listRes.status === 200) {
        setList((list) => ({
          ...list,
          data: listRes.result,
          loading: false,
          count: listRes.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  // =========data lang development===============>
  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData('lang/list', {})
      if (res.status === 200) {
        setLangs(res.data)

        const defaultLang = res.data.find(item => item.default)
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code)
        }
      }
    }

    loadLangs()
  }, [])

  const formatLangData = langs.map(lang => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code
  }))


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xxxxxl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <TabContainer
        tabs={formatLangData}
        active={selectedLangTab}
        setActive={(val) => setSelectedLangTab(val.toLowerCase())}
        changeTab={setSelectedLangTab}
      />
      <div className="flex justify-between w-full">
        <div className="relative w-full">
          <div className="mb-3">
            <Input
              label={"Title"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Title"}
              inputValue={name[selectedLangTab] || ''}
              setInput={(val) => setName(data => ({ ...data, [selectedLangTab]: val }))}
            />
          </div>
          <div className="mb-3">
            <Textarea
              label={"Sub Title"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Sub Title"}
              inputValue={subTitle[selectedLangTab] || ''}
              setTextarea={(val) => setSubTitle(data => ({ ...data, [selectedLangTab]: val }))}
            />
          </div>

          <div className="mb-3">
            <Editor
              apiKey={tinymceApiKey()}
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={description[selectedLangTab] || ''}
              // init={{ height: 400 }}
              init={tinyMceInit()}
              onEditorChange={(newText) => setDescription(data => ({
                ...data,
                [selectedLangTab]: newText
              }))}
            />
          </div>

        </div>
        <div className="w-[550px] ml-4 pl-4 border-l border-solid border-gray-200">
          <div className="mb-3">
            <Input
              label={"Alias"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter Description"}
              inputValue={alias}
              setInput={setAlias}
              isDisabled={true}
            />
          </div>
          {
            hasImageField &&
            <div className="relative mb-3">
              {image && (
                <div className="relative w-full rounded border border-slate-200 flex overflow-hidden mb-3">
                  <Button
                    buttonClasses={"!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-rose-500 !w-6 !h-6 !rounded-full !shadow !text-sm"}
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-xmark"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    functions={() => setImage(null)}
                  />
                  <Image
                    src={image instanceof File ? URL.createObjectURL(image) : image}
                    alt={name}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-fit"}
                  />
                </div>
              )}
              <div className="relative mb-3">
                <ButtonFile
                  functions={onImageChange}
                  buttonLabel="Upload Image"
                  buttonIconPosition="left"
                  buttonIcon="fa-arrow-up-from-bracket"
                />
              </div>
            </div>
          }
        </div>
      </div>


    </AddEditLayoutModal>
  );
};

export default EditPages;
