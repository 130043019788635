import React from 'react'
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
import AddEditAdminModal from "../components/modals/AddEditAdminModal";
import AddEditCompaniesModal from "../components/modals/AddEditCompaniesModal";
import AddEditUsersModal from "../components/modals/AddEditUserModal";
import { useDispatch } from "react-redux";
import { courseListData, listData, userListData, userTabChange } from "../store/companiesSlice";
import { API_URL } from '../config/host';
import { setTitle } from "../helpers/MetaTag";

const Users = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [userId, setUserId] = useState(user?._id)
  const tabChangeStatus = useSelector((state) => state.companyListData.tabChange);
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'Active', type: 'checkbox', value: 1, isChecked: false },
    { _id: 2, name: 'Inactive', type: 'checkbox', value: 2, isChecked: false },
  ]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState({})
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  setTitle(`Radisol | Users`);


  // data fetching===========>

  useEffect(() => {

    if (user?._id) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          setOffset(0)
        }
        const checkedStatusFilter = statusFilterData.filter((id) => id.isChecked)
        if (checkedStatusFilter.length > 0) {
          searchParams.status = checkedStatusFilter.map((item) => item.value)
        }

        const res = await postData("user/list", {
          ...searchParams,
          keyword: keyword,
          type: "user",
          limit,
          offset,
          sortQuery:
            sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
  statusFilterData.filter((id) => id.isChecked).length,
]);

// only company list add in redux store

useEffect(() => {
    const loadData = async () => {
      const res = await postData("user/list", {
        type: "company",
        status: true,
      });
      if (res.status === 200) {
        dispatch(listData(res.data))
      } else {
      }
    };
    loadData();

}, [dispatch])


  // change status==============>

  const changeStatus = async (id) => {

    if (id !== userId) {
      const res = await postData('user/status/manage', {
        id: id
      });

      if (res.status === 200) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== id
              ? item
              : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
              }
          ),
        }));
        toastr.success(res.message);
      }
    } else {
      toastr.error("Admin can't change own status");
    }

  };

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    item._id !== userId &&
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: changeStatus,
    },
    location.pathname === "/users" &&
    {
      name: "Preview",
      icon: "fa-eye",
      type: "link",
      link: "/user-details",
    },
    item._id !== userId &&
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "user/delete",
      deletePayload: { id: item._id },
      standout: true
    },
  ];

  // const onDrag = async (data = []) => {
  //   const res = await postData("crud/ordering", {
  //     table_name: "users",
  //     ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
  //   });

  //   if (res.status !== 200) {
  //     toastr.error(res.message);
  //   }
  // };

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 1,
      width: 15,
      title: "email",
      name: "email",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 1,
      width: 15,
      title: "phone",
      name: "phone",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 3,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      width: 15,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {
        // console.log('id', id, statusFilterData.map(item => item._id !== id ? item : {
        //   ...item,
        //   isChecked: !item.isChecked
        // }))
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                ...item,
                isChecked: !item.isChecked,
              }
          )
        );
      },
    },
    {
      _id: 4,
      title: "Action",
      action: true,
    },
  ];
  return (
    <>
      <ListLayout
        title={"Users"}
        addName="Add"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={false}
          functions={changeStatus}
        // onDrag={onDrag}
        />
      </ListLayout>
        <AddEditUsersModal
          show={showModal}
          setShow={setShowModal}
          title={
            Object.keys(editData).length > 0 ? "Edit User" : "Add User"
          }
          list={list.data}
          setList={setList}
          editData={editData}
          setEditData={setEditData}
          addType='DESC'
        />
    </>
  )
}

export default Users