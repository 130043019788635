import { combineReducers } from "redux";
import { authSlice } from "./authSlice";
import settingsSlice from "./settingsSlice";
import companiesSlice from "./companiesSlice";
import quizeResultSlice from "./quizeResultSlice";
import paramSlice from "./paramSlice";

export default combineReducers({
  auth: authSlice.reducer,
  siteLogo: settingsSlice,
  companyListData: companiesSlice,
  quizResultsData: quizeResultSlice,
  paramUrlState: paramSlice,
})