import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Table from '../components/elements/table/Table';
import TableAlt from '../components/elements/table/TableAlt';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
// import { convertTimezone, formatDate } from '../helpers';
import toastr from 'toastr';
import AddEditCourseModal from '../components/modals/AddEditCourseModal';
import { API_URL } from '../config/host';
import { setTitle } from '../helpers/MetaTag';

const Course = () => {
  setTitle('Radisol | Course');
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [dateRange, setDateRange] = useState(null)
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  console.log(dateRange,"dateRange")
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'Active', type: 'checkbox', value: true, isChecked: false },
    { _id: 2, name: 'Inactive', type: 'checkbox', value: false, isChecked: false },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})


  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        if (dateRange && (!dateRange.start || !dateRange.end)) {
          return
        }
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {}
        if (keyword) {
          setOffset(0)
          setLimit(10)
          searchParams.keyword = keyword;
        }

        const checkedStatusFilter = statusFilterData.filter((id) => id.isChecked)
        if (checkedStatusFilter.length > 0) {
          searchParams.status = checkedStatusFilter.map((item) => item.value)
        } else {
          searchParams.status = [true, false]
        }

        const res = await postData('course/list', {
          ...searchParams,
          dateRange:dateRange,
          sortByField: sortField,
          sortByValue: sortType,
          limit: limit,
          offset: offset,
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      }
      loadData()
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType, dateRange, statusFilterData])

  

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'Title',
      name: 'title',
      align: 'left',
      sortable: true,
      filterable: false,
      langVal: true
    },
    {
      _id: 3,
      width: 15,
      title: 'Short Description',
      name: 'sub_title',
      align: 'left',
      sortable: true,
      filterable: false,
      langVal: true,
    },
    {
      _id: 5,
      width: 15,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });
        } else {
          setDateRange(null)
        }
      },
    },
    {
      _id: 4,
      width: 15,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {
        // console.log('id', id, statusFilterData.map(item => item._id !== id ? item : {
        //   ...item,
        //   isChecked: !item.isChecked
        // }))
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                ...item,
                isChecked: !item.isChecked,
              }
          )
        );
      },
    },
    {
      _id: 6,
      title: "Action",
      action: true,
    },
  ];

  // console.log('statusFilterData', statusFilterData)

  const changeStatus = async (id, statusValue) => {
    const res = await postData('course/change-status', {
      id: id,
      status: !statusValue ? true : false
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,

              status: !(
                list.data.find((item) => item._id === id).status ||
                false
              ),
            }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'link',
      link: '/edit-course'
      // onClick: id => {
      //   setShowModal(true)
      //   setEditData(list.data.find(item => item._id === id) || {})
      // }
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: ()=>changeStatus(item._id,item.status),
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: 'course/remove',
      deletePayload: { id: item._id},
      standout: true
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData('course/ordering', {
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: offset+i })),
      
    })

    if (res.status !== 200) {
      toastr.error(res.message)
    } else {
      toastr.success(res.message)
    }
  }

  return (
    <>
      <ListLayout
        title='Courses'
        addName='Add Courses'
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        keyword={keyword}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          hasAction={true}
          isDraggable={true}
          onDrag={onDrag}
          functions={changeStatus}
        />
      </ListLayout>
      <AddEditCourseModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? 'Edit Course' : 'Add Course'}
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
        addType='DESC'
      />
    </>
  );
};

export default Course;