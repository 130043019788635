import { useState, useEffect } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';
import { API_URL } from '../../config/host';
// import Textarea from '../form/Textarea';
// import Image from '../elements/Image';
// import Select from '../form/Select';
// import Checkbox from '../form/Checkbox';
// import { Editor } from '@tinymce/tinymce-react';
import ButtonFile from '../form/ButtonFile';
import { postData } from '../../services/api';
// import { convertToBase64Async } from '../../helpers';
import toastr from 'toastr';
// import { tinymceApiKey,tinyMceInit } from '../../helpers';
import DropdownCheckbox from '../form/DropdownCheckbox';
import { useParams } from 'react-router-dom';
import TabContainer from '../elements/TabContainer';
// import { blobToImage } from '../../helpers';
// import Plyr from 'plyr';

const AddEditCurriculumVideoModal = ({
  title = '',
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = 'ASC',
}) => {
  const { id } = useParams();
  // const player = new Plyr('#player');
  // const editorRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState({});
  const [noOfQuestion, setNoOfQuestion] = useState('');
  const [quizTime, setQuizTime] = useState('');
  const [video, setVideo] = useState(null);
  const [quiz, setQuiz] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState();
  const [showProgressBar, setProgressBarVisibility] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [selectedLangTab, setSelectedLangTab] = useState('en')
  const [langs, setLangs] = useState([])
  const [passMarks, setPassMarks] = useState(null)
  const [allList, setAllList] = useState(0)
  const [progressError, setProgressError] = useState(false)


  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData('lang/list', {})
      if (res.status === 200) {
        setLangs(res.data)

        const defaultLang = res.data.find(item => item.default)
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code)
        }
      }
    }

    loadLangs()
  }, [])

  useEffect(() => {
    // console.log('editData',editData)
    if (editData?._id) {
      if (editData.name) {
        setName(editData.name)
      }

      setQuizTime(editData.quiz_time);
      setNoOfQuestion(editData?.no_of_question);
      setQuiz(editData?.quiz);
      setVideo(editData.video);
      setPassMarks(editData?.pass_mark || null)
      /* const loadVideo = async () => {
        let showVideo = await postData('file/get',{id: editData?.video_id});
        if(showVideo.status === 200){          
          files = new File([showVideo.data.blob], showVideo.data.name, showVideo.data.blob.mine_type);
          console.log('file',files);
          console.log(URL.createObjectURL(files));
        }        
      }
      loadVideo(); */
    }
  }, [editData]);

  useEffect(() => {
    if (editData?.quiz && quizData.length) {
      setQuizData((quizData) =>
        quizData.map((it) => {
          if (editData?.quiz.includes(it?._id)) {
            return {
              ...it,
              isEnabled: true,
            };
          } else {
            return it;
          }
        })
      );
    }
  }, [editData?.quiz, quizData.length]);

  const closeModal = () => {
    setShow(false);
    setName({});
    setQuiz([])
    setQuizTime('');
    setNoOfQuestion('');
    setDisabled(false);
    setEditData({});
    setVideo(null);
    setUploadPercentage(0);
    setProgressBarVisibility(false);
    setQuizData([]);
    setSelectedLangTab('en')
    setPassMarks(null)
  };

  const onVideoChange = (e) => {
    if (disabled) return;

    if (e.target.files[0]) {
      setVideo(null);

      const file = e.target.files[0];
      const allowedVideoTypes = [
        'video/x-flv',
        'video/mp4',
        'application/x-mpegURL',
        'video/MP2T',
        'video/3gpp',
        'video/quicktime',
        'video/x-msvideo',
        'video/x-ms-wmv',
      ];

      if (!allowedVideoTypes.includes(file.type)) {
        toastr.error('File is not a valid video.');
        return;
      }

      setTimeout(() => setVideo(file), 1);
    }
  };

  const onSubmit = async () => {
    setProgressError(false)
    setDisabled(true);
    setUploadPercentage(0);
    let videoId = undefined;
    const formData = new FormData();

    const docs = {
      name: name,
      no_of_question: noOfQuestion,
      quiz_time: quizTime,
      quiz: quiz,
      id: editData?._id || null,
      video_id: videoId || editData?.video_id,
      curriculum_id: id,
      ordering: editData?._id ? editData.ordering : list.length,
      pass_mark: parseInt(passMarks),
    }

    const jsonData = JSON.stringify(docs)
    formData.append("document", jsonData)

    if (quizData.filter(item => item.isEnabled).length > 0 && quizData.filter(item => item.isEnabled).length < noOfQuestion) {
      toastr.error(
        'Number of quiz should be similar or greater number of number of question.'
      )
      setDisabled(false)
      return
    }

    if (quizData.filter(item => item.isEnabled) > 0 && quiz.length < (noOfQuestion || 0)) {
      toastr.error('Please choose minimum ' + noOfQuestion + ' no of quiz');
      setDisabled(false);
      return;
    }

    if (video && video instanceof File) {
      setProgressBarVisibility(true);
      formData.append('file', video);
    }

    setUploadPercentage(0);

    const passMarkValidation = {}
    const passMarkValidationMessages = {}

    if (quizData.filter(item => item.isEnabled).length) {
      passMarkValidation['data.pass_mark'] = 'required'
      passMarkValidation['data.no_of_question'] = 'required|numeric'
      passMarkValidation['data.quiz_time'] = 'required|numeric'

      passMarkValidationMessages['required.data.no_of_question'] = 'No of question Field is required'
      passMarkValidationMessages['numeric.data.no_of_question'] = 'Only allow number'
      passMarkValidationMessages['required.data.quiz_time'] = 'Quiz time Field is required'
      passMarkValidationMessages['numeric.data.quiz_time'] = 'Only allow number'
      passMarkValidationMessages['required.data.quiz'] = 'Quiz field must not be empty.'
      passMarkValidationMessages['required.data.pass_mark'] = 'Pass Mark field must not be empty.'
    }

    const res = await postData(`curriculum/video/${editData?._id ? 'edit' : 'add'}`, formData, "multipart/form-data", setUploadPercentage);

    const formatCourseResData = (data) => {
      return {
        ...data,
        // category_details: {
        //   _id: categoryId,
        //   name: categories.find(item => item._id === categoryId)?.label || ''
        // }
      };
    };

    if (res.status === 200) {
      setProgressError(false)

      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList((list) => ({
            ...list,
            data: [...list.data, formatCourseResData(res.data)],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [formatCourseResData(res.data), ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        const curDetails = await postData('curriculum/video/list', {

        });
        if (curDetails.status === 200) {
          setList((list) => ({
            ...list,
            data: list.data.map((item) =>
              item._id !== editData._id
                ? item
                : formatCourseResData(curDetails.data)
            ),
          }));
        }
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setProgressError(true)
      setDisabled(false);
      toastr.error(res.message);
    }
  };




  useEffect(() => {
    const loadQuizzes = async () => {
      const res = await postData('admin/quiz/list', {
        status: [true],
        limit: allList
      });
      if (res.status === 200) {
        setAllList(res.count)
      }
      if (allList > 0) {
        Array.isArray(res.data) &&
          setQuizData(
            res.data.map((item, index) => {
              return {
                _id: item._id,
                label: item.question,
                value: item._id,
                isEnabled: false,
              };
            })
          );
      }
    };

    if (show) {
      loadQuizzes();
    }
  }, [show, allList]);

  const updateHandler = async (id, checked) => {
    setQuizData((quizData) =>
      quizData.map((it) => {
        if (it?._id === id) {
          return {
            ...it,
            isEnabled: checked,
          };
        } else {
          return it;
        }
      })
    );
    setQuiz((quiz) => {
      if (checked) {
        return [...quiz, id];
      } else {
        return quiz.filter((item) => item !== id);
      }
    });
  };

  const formatLangData = langs.map(lang => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code
  }))

  const setInputHandler = (val) => {
    if (val > 100) {
      setPassMarks(100)
    } else {
      setPassMarks(val)
    }
  }


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize='xxxxl'
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <TabContainer
        tabs={formatLangData}
        setActive={(val) => setSelectedLangTab(val.toLowerCase())}
        changeTab={setSelectedLangTab}
        active={selectedLangTab}
      />
      <div className='flex justify-between w-full'>
        <div className='relative w-full'>
          <div className='mb-3'>
            <Input
              label={'Video Title'}
              labelClasses={'!text-xs'}
              inputType={'text'}
              inputPlaceholder={'Enter Video title'}
              inputValue={name[selectedLangTab] || ''}
              setInput={(val) => setName(data => ({
                ...data,
                [selectedLangTab]: val
              }))}
            />
          </div>

          <div className='grid sm:grid-cols-2 gap-4'>
            <div className='mb-3'>
              <Input
                label={'Quiz time (Minute)'}
                labelClasses={'!text-xs'}
                inputType={'number'}
                inputPlaceholder={'Enter quiz time'}
                inputValue={quizTime}
                setInput={setQuizTime}
              />
            </div>
            <div className='mb-3'>
              <div className='relative w-full'>
                <DropdownCheckbox
                  label={'Quiz'}
                  labelClasses={'!text-xs'}
                  data={quizData}
                  functions={(id, event) => updateHandler(id, event)}
                  activeLang={selectedLangTab}
                  langMode
                />
              </div>
            </div>
          </div>
          <div className='grid sm:grid-cols-2 gap-4'>
            <div className='mb-3'>
              <Input
                label={'No of Question'}
                labelClasses={'!text-xs'}
                inputType={'number'}
                inputPlaceholder={'Enter no of question'}
                inputValue={noOfQuestion}
                setInput={setNoOfQuestion}
              />
            </div>
            <div className="mb-3">
              <Input
                label={'Pass Marks (%)'}
                labelClasses={'!text-xs'}
                inputType={'number'}
                inputPlaceholder={'Enter Pass Marks'}
                inputValue={passMarks}
                setInput={setInputHandler}
              />
            </div>
          </div>
        </div>
        <div className='w-[550px] ml-4 pl-4 pt-5 border-l border-solid border-gray-200'>
          {video && (
            <div className='relative w-full'>
              <div className="h-30 w-full rounded-md mb-2 overflow-hidden border border-slate-100">
                <video id='player' playsinline controls muted className="w-full h-[176px]">
                  <source src={video instanceof File ? URL.createObjectURL(video) : video} type='video/mp4' />
                </video>
              </div>
            </div>
          )}
          <div className='mb-3'>
            <ButtonFile
              functions={onVideoChange}
              buttonLabel='Upload Video'
              buttonIconPosition='left'
              buttonIcon='fa-arrow-up-from-bracket'
              accepts={'video/*'}
            />
          </div>
          {
            (video && !progressError) &&
            (showProgressBar && (
              <div className='mb-3 pt-6'>
                <div className='w-full bg-teal-500/25 rounded relative'>
                  <div className='bg-teal-500 text-[10px] font-medium text-white text-center leading-none rounded flex justify-center items-center h-4 relative transition-all duration-200' style={{ width: uploadPercentage + '%' }}>
                    {uploadPercentage > 0 && (
                      <span class="bg-teal-500 absolute -right-4 bottom-full mb-2 rounded-sm py-1 px-1 text-[10px] font-semibold text-white w-8 flex items-center justify-center">
                        <span class="bg-teal-500 absolute -bottom-1 left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm"></span>
                        {uploadPercentage}%
                      </span>
                    )}
                  </div>
                </div>
                <div class="font-medium text-slate-500 mt-1 text-xs text-center">Uploading Video</div>
              </div>
            ))
          }
        </div>
      </div>
    </AddEditLayoutModal>
  );
};
export default AddEditCurriculumVideoModal;
