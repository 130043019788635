import { useRef } from 'react';

const ButtonFile = ({
  buttonClasses,
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonFunction,
  functions,
  accepts,
  value = null,
  ...props
}) => {
  const imgRef = useRef(null)

  return (
    <>
      <div
        className={
          'relative flex justify-center items-center gap-2 border  rounded text-lg h-10 py-0 px-3 transition-all duration-200 overflow-hidden cursor-pointer !bg-white !text-slate-500 w-full hover:!bg-teal-500 hover:!text-white ' +
          buttonClasses
        }
      >
        <input
          ref={imgRef}
          type={'file'}
          className='absolute top-0 right-0 w-[500px] h-full z-10 opacity-0 cursor-pointer'
          onChange={(e) => {
            functions(e)
            imgRef.current.value = null
          }}
          accept={accepts}
          value={value||""}
        />
        {buttonIconPosition === 'left' && (
          <div className=' text-xl'>
            <i className={'fa-light fa-fw ' + buttonIcon}></i>
          </div>
        )}
        {buttonLabel && (
          <span className={'text-sm font-medium undefined ' + buttonLabelClasses}>
            {buttonLabel}
          </span>
        )}
        {buttonIconPosition === 'right' && (
          <i className={'fa-fw ' + buttonIcon}></i>
        )}
      </div>
    </>
  );
};

export default ButtonFile;
