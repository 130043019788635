import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectUrl: "overview",
  activeParam:"Overview"
}

const paramSlice = createSlice({
  name: "param url",
  initialState,
  reducers: {
    changeActiveParam: (state, action) => {
      state.activeParam = action.payload
    },
    changeSelectUrl: (state, action) => {
      state.selectUrl = action.payload
    },
  }
})

export const { changeParam,changeSelectUrl } = paramSlice.actions
export default paramSlice.reducer