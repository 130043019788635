import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quizeResultList: {
    loading: true,
    data: [],
    count: 0,
  }
}

const quizeResultSlice = createSlice({
  name: "quize result list",
  initialState,
  reducers: {
    listData: (state, action) => {
      state.quizeResultList = action.payload
    }
  }
})

export const { listData } = quizeResultSlice.actions
export default quizeResultSlice.reducer