import React from 'react'
import Image from '../components/elements/Image'
import ButtonFile from '../components/form/ButtonFile'
import Input from '../components/form/Input'
import { useState } from 'react'
import { Editor } from "@tinymce/tinymce-react";
import { tinyMceInit, tinymceApiKey } from '../helpers'
import { useRef } from 'react'
import Button from '../components/form/Button'
import { postData } from '../services/api'
import toastr from 'toastr';
import TabContainer from '../components/elements/TabContainer'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { API_URL } from '../config/host';
import { setTitle as setHeadingData } from '../helpers/MetaTag'




const Banner = () => {
  setHeadingData('Radisol | Banner');

  const user = useSelector((state) => state.auth.user);
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState('')
  const [title, setTitle] = useState({})
  const [bannerImg, setBannerImg] = useState()
  const [langs, setLangs] = useState([])
  const [description, setDescription] = useState({});
  const [selectedLangTab, setSelectedLangTab] = useState('en')
  const [list, setList] = useState({
    loading: true,
    data: {},
    count: 0,
  });

  useEffect(() => {
    if (Object.keys(list.data).length > 0) {
      setTitle(list.data.title)
      setDescription(list.data.description)
      setBannerImg(list.data.image)
      setEditId(list.data._id)
    }
  }, [list.data])

  // list==========>

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const res = await postData('banner/list', {})
        console.log(res, "b res")
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data || {},
            loading: false,
            // count: res.data.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      }
      loadData()
    }
  }, [user?._id])


  // multilanguage ===============>

  const formatLangData = langs.map(lang => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code
  }))

  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData('lang/list', {})
      if (res.status === 200) {
        setLangs(res.data)

        const defaultLang = res.data.find(item => item.default)
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code)
        }
      }
    }
    loadLangs()
  }, [])

  // Banner image select function=============>

  const onBannerImageChange = async (e) => {
    if (e.target.files.length) {
      setBannerImg(e.target.files[0]);
    }
  };

  // form submit function================>

  const handelSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    let imageId = undefined;

    const docs = {
      "title": title,
      "description": description,
      banner_img_id: imageId,
      id: editId || null,
    }

    const docsData = JSON.stringify(docs)

    if (bannerImg instanceof File) {
      formData.append('file', bannerImg);
    }

    formData.append("document", docsData);


    const res = await postData(`banner/edit`, formData,"multipart/form-data")
    if (res.status === 200) {
      setLoading(false);
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  }


  // form cancel function================>

  const handelCancel = () => { }
  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">Banner</div>
      </div>

      <div className="relative flex gap-4 ">
        <div className="w-[255px] space-y-4">

          <div className="relative bg-white rounded shadow">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className="w-full h-52 overflow-hidden rounded-md">
                {
                  bannerImg
                    ?
                    (
                      bannerImg && bannerImg instanceof File
                        ?
                        <Image
                          src={URL.createObjectURL(bannerImg)}
                          alt={"Banner Image"}
                          width={"100%"}
                          height={"100%"}
                          effect={"blur"}
                          classes={"object-contain"}
                          id={"image1"}
                        /> :
                        <Image
                          src={bannerImg}
                          alt={"Banner Image"}
                          width={"100%"}
                          height={"100%"}
                          effect={"blur"}
                          classes={"object-contain"}
                          id={"image2"}
                        />
                    )
                    :
                    <div className='img text-5xl text-gray-200 absolute top-0 left-0 h-full w-full flex items-center justify-center'>
                      <i className="fa-light fa-image"></i>
                    </div>
                }
              </div>
            </div>
            <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
              <ButtonFile
                buttonType={"button"}
                buttonIcon={
                  false ? "fa-light fa-spinner fa-spin" : "fa-light fa-image"
                }
                buttonIconPosition={"left"}
                buttonLabel={"Change Logo"}
                buttonClasses={
                  "!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white h-10"
                }
                functions={onBannerImageChange}
                accepts={"image/*"}
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">
                Banner Settings
              </div>
              <div className='mt-5'>
                <TabContainer
                  tabs={formatLangData}
                  active={selectedLangTab}
                  setActive={(val) => setSelectedLangTab(val.toLowerCase())}
                  changeTab={setSelectedLangTab}
                />
              </div>
              <div className="space-y-4">
                <div className="relative">
                  <Input
                    label={"Title"}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={"Enter Site Title"}
                    inputValue={title[selectedLangTab] || ''}
                    setInput={(val) => setTitle(data => ({
                      ...data,
                      [selectedLangTab]: val
                    }))}
                  />
                </div>

                <div className="relative">
                  <Editor
                    apiKey={tinymceApiKey()}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={description[selectedLangTab] || ''}
                    init={tinyMceInit()}
                    onEditorChange={(newText) => setDescription(data => ({
                      ...data,
                      [selectedLangTab]: newText
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                {/* <Button
                  buttonType={"button"}
                  buttonIcon={
                    false ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-slate-200 !text-slate-600 border border-slate-300"
                  }
                  functions={handelCancel}
                  buttonHasLink={false}
                  buttonDisabled={false}
                /> */}
                <Button
                  buttonType={"button"}
                  buttonIcon={
                    loading
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-check"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  functions={handelSubmit}
                  buttonHasLink={false}
                  buttonDisabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner