import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { postData } from "../services/api"
import toastr from "toastr"

const VerifyResetPassword = () => {
  const navigate = useNavigate()
  const { hash } = useParams()

  useEffect(() => {
    const verifyPassword = async () => {
      if (!hash) return

      const res = await postData('verify-admin-hash', {
        hash_number: hash
      })

      if (res.status === 200 && res.data) {
        navigate(`/reset-password/${hash}`)
      } else {
        toastr.error(res.message)
        navigate('/')
      }
    }

    verifyPassword()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  return <></>
}

export default VerifyResetPassword