const TableSort = ({  
  name = '',
  sortField = '',
  sortType = '',
  setSortField = () => {},
  setSortType = () => {},
}) => {
  return (
    <button
      type='button'
      className='relative w-4 h-4 bg-transparent text-slate-400'
      onClick={() => {
        setSortField(name)
        setSortType(sortType => sortType === 1 ? -1 : 1)
      }}
    >
      <span
        className={
          'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ' +
          (sortField !== name ? 'opacity-25' : sortType === 1 ? 'opacity-100' : 'opacity-25')
        }
      >
        <i className='fa-solid fa-fw fa-sort-up'></i>
      </span>
      <span
        className={
          'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ' +
          (sortField !== name ? 'opacity-25' : sortType === -1  ? 'opacity-100' : 'opacity-25')
        }
      >
        <i className='fa-solid fa-fw fa-sort-down'></i>
      </span>
    </button>
  );
};

export default TableSort;
