import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const ModalContainer = ({
  show = false,
  title = '',
  closeModal = () => { },
  modalSize = '',
  children,
}) => {
  const modalSizes = {
    '': 'max-w-xs',
    xs: 'max-w-xs',
    s: 'max-w-s',
    m: 'max-w-m',
    l: 'max-w-l',
    xl: 'max-w-xl',
    xxl: 'max-w-2xl',
    xxxl: 'max-w-3xl',
    xxxxl: 'max-w-4xl',
    xxxxxl: 'max-w-5xl',
    xxxxxxl: 'max-w-6xl',
    xxxxxxxl: 'max-w-7xl',
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={()=>{}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className={`w-full ${modalSizes[modalSize]} transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}>
                <Dialog.Title
                  as='h2'
                  className='text-2xl font-bold text-slate-600 text-center'
                >
                  {title}
                </Dialog.Title>
                <div className='absolute top-2 right-2'>
                  <button
                    type="button"
                    className="rounded-full flex items-center justify-center w-8 h-8 text-slate-300 text-2xl hover:text-rose-500 transition-all duration-200"
                    onClick={closeModal}
                  ><i className="fa-light fa-fw fa-xmark"></i></button>
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalContainer;
