import React, { useState } from 'react';
import Checkbox from './Checkbox';

const DropdownCheckbox = ({ data, label, labelClasses, functions = () => { }, langMode = false, activeLang = 'en', ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const count = data.filter((item) => item.isEnabled).length;
  return (
    <>
      <div className='relative'>
        {label &&
          <div className={"text-sm font-medium text-slate-500 mb-1 " + (labelClasses)}>{label}</div>
        }
        <div className='relative'>
          <button
            type='button'
            className='relative w-full h-10 focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-white rounded border border-slate-200 flex items-center cursor-pointer text-left '
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className='text-[13px] text-slate-500 font-medium whitespace-nowrap truncate w-full'>
              {count > 0 ? 'Selected ' + count : 'Selected'}
            </div>
            <div className='text-xs text-slate-400'>
              <i className={'fa-light fa-fw fa-chevron-down'}></i>
            </div>
          </button>
          <div
            className={
              'absolute top-full left-0 w-full bg-white rounded shadow mt-1 transition-all duration-200 z-10' +
              (isOpen ? '' : ' opacity-0 invisible')
            }
          >
            <div className='overflow-auto max-h-[200px] smooth-scroll scrollbar py-2'>
              {data.map((item, index) => (
                <div className='px-3 py-2' key={index}>
                  <Checkbox
                    checkboxName={!langMode ? item?.label : item?.label[activeLang]}
                    isChecked={item?.isEnabled}
                    checkboxLabel={!langMode ? item?.label : item?.label[activeLang]}
                    checkboxValue={item?._id}
                    getCheckboxValue={(id, event) =>
                      functions(id, event.target.checked)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DropdownCheckbox;
