import React, { useState, useEffect, useRef } from 'react';
import useOnClickOutside from '../../hook/useOnClickOutside';
import Checkbox from './Checkbox';
import Search from './Search';

const DropDownCheckBoxWithNameValue = ({ data, functions = () => { }, count, label, labelClasses, ...props }) => {
  const selectRef = useRef(null)
  const optionRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false);
  const [updateList,setUpdateList] = useState(data)
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    setUpdateList(data)
  }, [data])


  const getInputValue = (value) => {
    setKeyword(value)
    if(!value){
      setUpdateList(data)
      return
    }
    const list = data.filter((item) => {
          return (item.name||"").toLowerCase().includes(keyword.toLowerCase())
        })
        setUpdateList(list)
  }

  useOnClickOutside(selectRef, () => setIsOpen(false), optionRef)
  return (
    <>
      <div className='relative'>
        {label &&
          <div className={"text-sm font-medium text-slate-500 mb-1 " + (labelClasses)}>{label}</div>
        }
        <button
          type='button'
          className='relative w-full h-10 focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-white rounded border border-slate-200 flex items-center cursor-pointer text-left '
          onClick={() => setIsOpen(!isOpen)}
          ref={selectRef}
        >
          <div className='text-[13px] text-slate-500 font-medium whitespace-nowrap truncate w-full'>
            {count.length > 0 ? 'Selected ' + count.length : 'Select'}
          </div>
          <div className='text-xs text-slate-400'>
            <i className={'fa-light fa-fw fa-chevron-down'}></i>
          </div>
        </button>
        <div
          className={
            'absolute top-full left-0 w-full bg-white rounded shadow mt-1 transition-all duration-200 z-10' +
            (isOpen ? '' : ' opacity-0 invisible')
          }
          ref={optionRef}
        >
          <div className='overflow-auto max-h-[200px] smooth-scroll scrollbar py-2'>
            <div setInput={getInputValue} value={keyword} className='mx-2'>
              <Search setInput={getInputValue} value={keyword} placeholder={"Search"} />
            </div>
            {updateList.map((item, index) => (
              <div className='px-3 py-2' key={index}>
                <Checkbox
                  checkboxName={item?.name}
                  isChecked={item?.isEnabled}
                  checkboxLabel={item?.name}
                  checkboxValue={item?._id}
                  getCheckboxValue={(id, event) =>
                    functions(id, event.target.checked)
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropDownCheckBoxWithNameValue;
