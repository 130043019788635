// const BASE_URL = 'https://haldus.kiirguskoolitus.ee/api/v1/';
 const BASE_URL = 'https://radisolapi.iosx.in:3044/api/v1/';
//const BASE_URL = 'http://localhost:3044/api/v1/';
// export const API_URL = 'https://haldus.kiirguskoolitus.ee/';
export const API_URL = 'https://radisol.fra1.digitaloceanspaces.com/';
export const DIGI_IMG_URL = "https://radisol.fra1.digitaloceanspaces.com/"
// const BASE_URL = 'https://radisolapi.iosx.in:3044/api/v1/';
// export const API_URL = 'https://radisolapi.iosx.in:3044/';
export default BASE_URL;

// const BASE_URL = 'http://localhost:3044/api/v1/';
// export const API_URL = 'http://localhost:3044/';
// export default BASE_URL;