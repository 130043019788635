import { useEffect, useState, useRef } from 'react';
import Button from "./Button";
import Radio from "./Radio";

const OptionsInput = ({
  label,
  labelClasses,
  inputType,
  inputClasses,
  inputPlaceholder,
  inputValue,
  inputName,
  errorType,
  errorText,
  isDisabled,
  setInput,
  functions,
  field,
  correct = false,
  index,
  onCorrect = () => {},
  ...props
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const passwordInput = useRef(null);
  const handleChange = () => {
    setPasswordVisibility(!passwordVisibility);
  }

  useEffect(() => {
    passwordInput.current.type = passwordVisibility ? 'text' : inputType;
  }, [inputType, passwordVisibility]);


  return (
    <>
      {label &&
        <div className={"text-sm font-medium text-slate-500 mb-1 " + (labelClasses)}>{label}</div>
      }
      <div className="relative flex items-center">
        <Radio 
        isChecked={correct}
        radioName={"options"}
        onChange={() => onCorrect(index)}
        />
        
        {isDisabled ? (
          <div className='flex items-center w-full gap-4'>
            <input
              type={inputType}
              ref={passwordInput}
              className={"w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 " + (inputClasses)}
              placeholder={inputPlaceholder}
              name={inputName}
              value={inputValue}
              autoComplete={'new-' + (inputType)}
              disabled
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  functions(e);
                }
              }}
            />
            <Button
              buttonClasses={"!w-10 !h-10 remove-field !bg-rose-500"}
              buttonType={"button"}
              buttonIcon={"fa-light fa-xmark"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              functions={functions}
            />
          </div>
        ) : (
          <div className='flex items-center w-full gap-4'>
            <input
              type={inputType}
              ref={passwordInput}
              className={"w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 " + (inputClasses)}
              placeholder={inputPlaceholder}
              name={inputName}
              value={inputValue}
              autoComplete={'new-' + (inputType)}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  functions(e);
                }
              }}
            />
            {
              field.length > 1 && 
              <Button
                buttonClasses={"!w-10 !h-10 remove-field !bg-rose-500"}
                buttonType={"button"}
                buttonIcon={"fa-light fa-xmark"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                functions={functions}
              />
            }
          </div>
        )}
        {inputType === 'password' &&
          <button
            type="button"
            className="w-10 h-10 absolute top-0 right-0 bg-transparent text-slate-500"
            onClick={handleChange}
          >
            <i className={"fa-regular fa-fw " + (passwordVisibility ? 'fa-eye-slash' : 'fa-eye')}></i>
          </button>
        }
      </div>
      {errorType && errorType === 'error' &&
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>{errorText}
        </div>
      }
      {errorType && errorType === 'warning' &&
        <div className="text-xs text-amber-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>{errorText}
        </div>
      }
      {errorType && errorType === 'success' &&
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>{errorText}
        </div>
      }
      {errorType && errorType === 'info' &&
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>{errorText}
        </div>
      }
    </>
  );
};

export default OptionsInput;