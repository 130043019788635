import ModalContainer from '../ModalContainer';
// import Input from '../../components/form/Input';
import Button from '../../components/form/Button';

const AddEditLayoutModal = ({
  show = false,
  disabled = false,
  title = '',
  onClose = () => {},
  onSubmit = () => {},
  modalSize = '',
  children
}) => {
  return (
    <ModalContainer show={show} title={title} closeModal={onClose} modalSize={modalSize}>
      <div className='relative'>
        <div className='mb-4 flex flex-col'>
          <div className='space-y-4 mt-4'>
            <div className='flex items-center flex-col '>
              {/* <div className='relative w-full'>
                <Input
                  label={'Category Name'}
                  labelClasses={'!text-xs'}
                  inputType={'text'}
                  inputPlaceholder={'Enter Category Name'}
                  inputValue={''}
                  setInput={() => {}}
                />
              </div> */}
              { children }
            </div>
          </div>
        </div>
        <div className='pt-3 px-6 -mx-6 border-t border-slate-200'>
          <div className='flex items-center gap-4'>
            <Button
              buttonClasses='!w-auto'
              buttonLabelClasses=''
              buttonType={'button'}
              buttonIcon={
                disabled
                  ? 'fa-light fa-spinner fa-spin'
                  : 'fa-light fa-check'
              }
              buttonIconPosition={'left'}
              buttonLabel={'Submit'}
              functions={onSubmit}
              buttonHasLink={false}
              buttonDisabled={disabled}
            />
            <Button
              buttonClasses='!w-auto !bg-white !text-teal-500 !border !border-teal-500'
              buttonLabelClasses=''
              buttonType={'button'}
              buttonIcon={"fa-light fa-xmark"}
              buttonIconPosition={'left'}
              buttonLabel={'Cancel'}
              functions={onClose}
              buttonHasLink={false}
              buttonDisabled={disabled}
            />
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default AddEditLayoutModal;
