import React from 'react';
import Button from '../components/form/Button';
import UserDropdown from './UserDropdown';

const DefaultHeader = ({isMenuOpen, setIsMenuOpen}) => {  
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  }
  
  return (
    <>
      <header className="sticky top-0 h-16 min-h-[64px] z-50 w-full flex items-center px-6 bg-white shadow gap-4">
        <div className="relative">
          <Button buttonType={'button'} buttonIcon={'fa-solid fa-bars'} buttonIconPosition={'left'} buttonClasses={'px-0 w-10 !bg-slate-200 !text-slate-600 hover:!bg-slate-300 hover:!text-slate-900'} functions={menuToggle}/>
        </div>
        <div className="relative ml-auto flex justify-end items-center gap-3">
          {/* <div className="relative px-1 border-x border-slate-200">
            <NotificationDropdown xPlacement={'right'} />
          </div> */}
          <div className="relative">
            <UserDropdown xPlacement={'right'} />
          </div>
        </div>
      </header>
    </>
  );
};

export default DefaultHeader;