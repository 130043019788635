import { useState, useEffect } from 'react';
import Input from '../form/Input';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import { postData } from '../../services/api';
import toastr from 'toastr';

const AddEditTagsModal = ({
  title = '',
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  list = {
    data: []
  },
  setList = () => {},
  addType = 'ASC'
}) => {
  const [disabled, setDisabled] = useState()
  const [name, setName] = useState('');

  useEffect(() => {
    if (editData?._id) {
      setName(editData.tag_name)
    }
  }, [ editData ])

  const closeModal = () => {
    setShow(false);
    setName('')
    setDisabled(false)
    setEditData({})
  };

  const onSubmit = async () => {
    setDisabled(true)

    const res = await postData(`crud/${editData?._id ? 'edit'  : 'add'}`, { 
      table_name:"tag",
      data: {
        tag_name:name,
        id:  editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.data.length
      },
      validationRules: {
        'data.tag_name': 'required',
        // 'data.ordering': 'required'
      },
      validationMessages: {
        'required.data.tag_name': 'Tag Field is required',
        // 'required.data.ordering': 'Ordering Field is required',
      }
    })

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [ ...list.data, res.data ],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [ res.data, ...list.data, ],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : res.data)
        }))
      }

      closeModal()
      toastr.success(res.message)
    } else {
      setDisabled(false)
      toastr.error(res.message)
    }
  }


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className='relative w-full'>
        <Input
          label={'Tag Name'}
          labelClasses={'!text-xs'}
          inputType={'text'}
          inputPlaceholder={'Enter Tag Name'}
          inputValue={name}
          setInput={setName}
        />
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditTagsModal;
