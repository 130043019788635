import { useState, useEffect, Fragment, useRef } from "react";
import Input from "../components/form/Input";
import Textarea from "../components/form/Textarea";
import Select from "../components/form/Select";
import Image from "../components/elements/Image";
import ButtonFile from "../components/form/ButtonFile";
import Button from "../components/form/Button";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import toastr from "toastr";
import { postData } from "../services/api";
import { tinymceApiKey, tinyMceInit } from "../helpers";
import ListLayoutAlt from "../containers/ListLayoutAlt";
import TableAlt from "../components/elements/table/TableAlt";
import { useSelector } from "react-redux";
import AddEditCurriculumModal from "../components/modals/AddEditCurriculumModal";
import AddEditFaqModal from "../components/modals/AddEditFaqModal";
import { API_URL } from "../config/host";
import TabContainer from "../components/elements/TabContainer";
import { decodeHTML } from "../helpers";


const ManageCourse = () => {
  const { id } = useParams();
  const editorRef = useRef(null);
  const user = useSelector((state) => state.auth.user);


  const [active, setActive] = useState("Overview");
  const [details, setDetails] = useState({});
  const [, setCategories] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [selectTab, setSelectTab] = useState("overview");
  const [selectedLangTab, setSelectedLangTab] = useState("en");
  const [isOverview, setIsOverview] = useState(false);
  const [description, setDescription] = useState({});
  const [title, setTitle] = useState({});
  const [subTitle, setSubTitle] = useState({});
  const [, setCategoryId] = useState("");
  const [instructorId, setInstructorId] = useState(null);
  const [, setTags] = useState([]);
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState("");
  const [modalType, setModalType] = useState()
  const [orderingLoading, setOrderingLoading] = useState(false)
  const [instructorListCount, setInstructorListCount] = useState(0)
  // Curriculums
  const [curriculumsShowModal, setCurriculumsShowModal] = useState(false);
  const [curriculumEditData, setCurriculumEditData] = useState({});
  const [curriculumList, setCurriculumList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [curriculumLimit, setCurriculumLimit] = useState(10);
  const [curriculumOffset, setCurriculumOffset] = useState(0);
  const [curriculumKeyword, setCurriculumKeyword] = useState("");
  const [selectedLimit, setSelectedLimit] = useState(undefined)

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [faqList, setFaqList] = useState({
    loading: true,
    data: [],
    count: 0,
  })

  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'Active', type: 'checkbox', value: true, isChecked: false },
    { _id: 2, name: 'Inactive', type: 'checkbox', value: false, isChecked: false },
  ]);

  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [video, setVideo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [langs, setLangs] = useState([]);
  // let files;

  const dropdownData = [
    {
      _id: 1,
      label: "Overview",
      icon: "fa-circle-info",
      link: "/",
      type: "button",
      current: selectTab && selectTab === "overview" ? true : false,
      code: "overview",
    },
    {
      _id: 2,
      label: "Curriculum",
      icon: "fa-video-camera",
      link: "/",
      type: "button",
      current: selectTab && selectTab === "curriculum" ? true : false,
      code: "curriculum",
    },
    // {
    //     _id: 3,
    //     label: "Instructor",
    //     icon: "fa-user",
    //     link: "/",
    //     type: "button",
    //     current: selectTab && selectTab === "instructor" ? true : false,
    //     code: 'instructor'
    // },
    // {
    //   _id: 4,
    //   label: "Reviews",
    //   icon: "fa-star",
    //   link: "/",
    //   type: "button",
    //   current: selectTab && selectTab === "reviews" ? true : false,
    //   code: "reviews",
    // },
    {
      _id: 5,
      label: "FAQs",
      icon: "fa-question-circle",
      link: "/",
      type: "button",
      current: selectTab && selectTab === "faqs" ? true : false,
      code: "faqs",
    },
    // {
    //   _id: 6,
    //   label: "Comment",
    //   icon: "fa-comment",
    //   link: "/",
    //   type: "button",
    //   current: selectTab && selectTab === "comment" ? true : false,
    //   code: "comment",
    // },
  ];

  const formatLangData = langs.map((lang) => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code,
  }));

  const changeTab = async (code) => {
    setSelectTab(code);
  };

  const onImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml"];


      if (!allowedImageTypes.includes(file.type)) {
        toastr.error("File is not a valid image.");
        return;
      }
      setImage(file);
    }
  };

  const onVideoChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const allowedVideoTypes = ["video/x-flv", "video/mp4", "application/x-mpegURL", "video/MP2T", "video/vnd.dlna.mpeg-tts", "video/3gpp", "video/quicktime", "video/x-msvideo", "video/x-ms-wmv"];

      if (!allowedVideoTypes.includes(file.type)) {
        toastr.error("File is not a valid video.");
        return;
      }
      setVideo(file);
    }
  };

  useEffect(() => {
    loadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadDetails = async () => {
    try {
      const result = await postData("admin/course/details", {
        id: id,
      })
      if (result.data) {
        setDetails(result.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  // details data store in state=========>
  useEffect(() => {
    if (details?.title) {
      setTitle(details?.title);
    }

    if (details?.sub_title) {
      setSubTitle(details?.sub_title);
    }

    setCategoryId(details?.category_id);
    setInstructorId(details?.instructor_id);

    if (details?.description) {
      setDescription(details?.description);
    }

    if (details.tags && details.tags.length > 0) {
      setTags((tags) =>
        tags.map((item) => {
          let key = details.tags.findIndex((itm) => itm._id === item?._id);
          if (key !== -1) {
            return {
              ...item,
              isEnabled: details.tags[key].isEnabled,
            };
          } else {
            return {
              ...item,
            };
          }
        })
      );
    }

    setShowImage(details.image);
    setVideo(details.video);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?._id]);

  // over view or course edit============>
  const onOverview = async (e) => {
    setIsOverview(true);
    let imageId = null;
    let videoId = null;
    const formData = new FormData();

    const docs = {
      title: title,
      sub_title: subTitle,
      description: description,
      // category_id: null, //categoryId,
      instructor_id: instructorId,
      tags: null, //tags,
      image_id: imageId,
      video_id: videoId,
      id: id,
      hasLang: true,
    }


    try {
      const jsonData = JSON.stringify(docs);
      if (image && image instanceof File) {
        formData.append("file", image);
      }
      if (video && video instanceof File) {
        formData.append("video", video);
      }
      formData.append("document", jsonData);


      const result = await postData("course/edit", formData, "multipart/form-data");
      if (result.status && result.status === 200) {
        // loadDetails()
        toastr.success(result.message);
        setIsOverview(false);
      } else {
        toastr.error(result.message);
        setIsOverview(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsOverview(false);
    }
  };
  // instructor list api call===========>
  useEffect(() => {
    const loadInstructors = async () => {
      const res = await postData("instructor/list", {
        limit: instructorListCount
      });
      if (res.status === 200) {
        setInstructorListCount(res.count)
        let ins = [{ _id: '', label: 'Select Insructor', value: '' }];
        Array.isArray(res.data) &&
          res.data.forEach((item, index) => {
            ins.push({ _id: item._id, label: item.name, value: item._id });
          });
        setInstructors(ins);
      }
    };
    loadInstructors();
  }, [])

  // Curriculums section ==============>

  useEffect(() => {
    if (user?._id) {
      const loadCurriculumData = async () => {
        if (dateRange && (!dateRange.start || !dateRange.end)) {
          return
        }
        setCurriculumList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (curriculumKeyword) {
          setCurriculumLimit(10)
          setCurriculumOffset(0)
          searchParams.keyword = curriculumKeyword
        }
        const checkedStatusFilter = statusFilterData.filter((id) => id.isChecked)
        if (checkedStatusFilter.length > 0) {
          searchParams.status = checkedStatusFilter.map((item) => item.value)
        }
        const res = await postData("curriculum/list", {
          ...searchParams,
          course_id:id,
          dateRange: dateRange,
          sortByField: sortField,
          sortByValue: sortType,
          limit: curriculumLimit,
          offset: curriculumOffset,
        });
        if (res.status === 200) {
          setCurriculumList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setCurriculumList((list) => ({ ...list, loading: false }));
        }
      };
      loadCurriculumData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, curriculumKeyword, curriculumLimit, curriculumOffset, sortField, sortType, selectedLangTab, statusFilterData, dateRange]);

  const onDragCurriculum = async (data = []) => {
    const res = await postData("curriculum/ordering", {
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: offset+i })),
    });
    if (res.status !== 200) {
      toastr.error(res.message);
    } else {
      toastr.success(res.message);
    }
  };


  const tableCurriculumsHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
      langVal: true,
    },
    {
      _id: 2,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });
        } else {
          setDateRange(null)
        }
      },
    },
    {
      _id: 3,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {
        // console.log('id', id, statusFilterData.map(item => item._id !== id ? item : {
        //   ...item,
        //   isChecked: !item.isChecked
        // }))
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                ...item,
                isChecked: !item.isChecked,
              }
          )
        );
      },
    },
    {
      _id: 4,
      title: "Action",
      action: true,
    },
  ];

  const actionCurriculumsData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setCurriculumsShowModal(true);
        setCurriculumEditData(curriculumList.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: "Video List",
      icon: "fa-list",
      type: "link",
      link: "/curriculums",
      // onClick: (id) => {
      //   setCurriculumEditData(curriculumList.data.find((item) => item._id === id) || {});
      // },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: ()=>changeCurriculumsStatus(item._id,item.status),
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "curriculum/remove",
      deletePayload: { id: item._id },
      standout: true
    },
  ];

  const changeCurriculumsStatus = async (id, statusValue) => {
    const res = await postData("curriculum/change-status", {
      id: id,
      status: !statusValue ? true : false
    });

    if (res.status === 200) {
      setCurriculumList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,

              status: !(list.data.find((item) => item._id === id).status || false),
            }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  // faq ============>

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        if (dateRange && (!dateRange.start || !dateRange.end)) {
          return
        }
        setFaqList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          setOffset(0)
          setLimit(10)
          searchParams.keyword = keyword;
        }

        const checkedStatusFilter = statusFilterData.filter((id) => id.isChecked)
        if (checkedStatusFilter.length > 0) {
          searchParams.status = checkedStatusFilter.map((item) => item.value)
        }

        const res = await postData("faq/list", {
          ...searchParams,
          dateRange: dateRange,
          sortByField: sortField,
          sortByValue: sortType,
          limit: limit,
          offset: offset,
        });
        if (res.status === 200) {
          setFaqList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setFaqList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id, keyword, limit, offset, sortField, sortType, statusFilterData, dateRange]);

  const onDragFaq = async (data = []) => {
    const res = await postData("faq/ordering", {
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: offset+i })),
    });
    if (res.status !== 200) {
      toastr.error(res.message);
    } else {
      toastr.success(res.message);
    }
  };

  // change status============>
  const changeStatus = async (id, statusValue) => {
    const res = await postData("faq/change-status", {
      id: id,
      status: !statusValue ? true : false
    });

    if (res.status === 200) {
      setFaqList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,

              status: !(list.data.find((item) => item._id === id).status || false),
            }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  }

  // action button data edit delete etc========>
  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(faqList.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: ()=>changeStatus(item._id,item.status),
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "faq/remove",
      deletePayload: { id: item._id },
      standout: true
    },
  ];

  // overview table header data==========>
  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Question",
      name: "question",
      align: "left",
      sortable: true,
      filterable: false,
      langVal: true,
    },
    {
      _id: 2,
      width: 15,
      title: "Answer",
      name: "answer",
      align: "left",
      sortable: true,
      filterable: false,
      langVal: true,
      html: true
    },
    {
      _id: 4,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });
        } else {
          setDateRange(null)
        }
      },
    },
    {
      _id: 5,
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      filterOptions: statusFilterData,
      sortable: true,
      filterable: true,
      onFilterChange: (id) => {
        // console.log('id', id, statusFilterData.map(item => item._id !== id ? item : {
        //   ...item,
        //   isChecked: !item.isChecked
        // }))
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                ...item,
                isChecked: !item.isChecked,
              }
          )
        );
      },
    },
    {
      _id: 6,
      title: "Action",
      action: true,
    },
  ];

  return (
    <>

      <div className="relative d-flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">{details?.title ? details?.title[selectedLangTab] : ""}</div>
        <div className="text-base text-slate-800">{details?.sub_title ? details?.sub_title[selectedLangTab] : ""}</div>
      </div>
      <div className="relative ml-auto justify-start items-center">
        <TabContainer active={active} changeTab={changeTab} setActive={setActive} tabs={dropdownData} />
      </div>

      {
        dropdownData.map((item, index) => (
          <Fragment key={index}>
            {selectTab && selectTab === "overview" && (
              <div className={`${!item?.current ? "hidden" : ""}`} id="overview" role="tabpanel" aria-labelledby="overview-tab">
                {active === "Overview" && <TabContainer setActive={(val) => setSelectedLangTab(val.toLowerCase())} active={selectedLangTab} changeTab={setSelectedLangTab} tabs={formatLangData} />}
                <div className="relative grid grid-cols-4 gap-4">
                  <div className="col-span-3">
                    <div className="relative bg-white rounded shadow divide-y divide-slate-200">
                      <div className="py-8 px-6 flex flex-col">
                        <div className="space-y-4 mt-4">
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                              <Input
                                label={"Course Name"}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={"Enter Course Name"}
                                inputValue={title[selectedLangTab] || ""}
                                setInput={(val) =>
                                  setTitle((data) => ({
                                    ...data,
                                    [selectedLangTab]: val,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                              <Textarea
                                label={"Course Short Description"}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={"Enter Course Description"}
                                inputValue={subTitle[selectedLangTab] || ""}
                                setTextarea={(val) =>
                                  setSubTitle((data) => ({
                                    ...data,
                                    [selectedLangTab]: val,
                                  }))
                                }
                              />
                            </div>
                          </div>
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                            <div className="font-medium text-slate-500 mb-1 !text-xs">Description</div>
                              <Editor
                                apiKey={tinymceApiKey()}
                                onInit={(evt, editor) => (editorRef.current = editor)}
                                value={decodeHTML(description[selectedLangTab]) || ""}
                                init={tinyMceInit()}
                                onEditorChange={(newText) =>
                                  setDescription((data) => ({
                                    ...data,
                                    [selectedLangTab]: newText,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="py-3 px-3 border-t border-slate-200">
                        <div className="flex items-center justify-end gap-4">
                          <Button
                            buttonClasses="!font-semibold !text-xs !leading-tight !uppercase !h-10 !-slate-400 !border-b-2 !border-transparent !whitespace-nowrap"
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={isOverview ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"}
                            buttonIconPosition={"left"}
                            buttonLabel={"Save"}
                            functions={onOverview}
                            buttonHasLink={false}
                            buttonDisabled={isOverview}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="sticky top-12 bg-white rounded shadow">
                      <div className="mb-3 p-4">
                        {(showImage || (image !== null && image instanceof File)) && (
                          <div className="relative w-full">
                            <div
                              className="cursor-pointer bg-white text-rose-500 w-6 h-6 flex justify-center items-center absolute rounded-full shadow -top-2 -right-2"
                              onClick={() => {
                                setImage(null);
                                setShowImage(null);
                              }}
                            >
                              <i className="fa-regular fa-fw fa-xmark"></i>
                            </div>
                            <div className="h-30 w-full rounded-md mb-2 overflow-hidden border border-slate-100">
                              <Image src={image ? URL.createObjectURL(image) : showImage} alt={title?.en} />
                            </div>
                          </div>
                        )}
                        <ButtonFile accepts="image/*" functions={onImageChange} buttonLabel="Upload Image" buttonIconPosition="left" buttonIcon="fa-arrow-up-from-bracket" />
                      </div>
                      <div className="mb-3 p-4">
                        {/* Video */}
                        <div className="relative w-full">{video &&
                          <div className="h-30 w-full rounded-md mb-2 overflow-hidden border border-slate-100">
                            <video className="w-full h-[176px]" controls src={video instanceof File ? URL.createObjectURL(video) : video}></video>
                          </div>
                        }</div>
                        <ButtonFile accepts="video/*" functions={onVideoChange} buttonLabel="Upload Video" buttonIconPosition="left" buttonIcon="fa-arrow-up-from-bracket" />
                      </div>
                      <div className="mb-3 p-4">
                        {
                          (instructorId?.length !== 0 || instructorId !== null) ?
                            <Select
                              xPlacement={"left"}
                              dropdownButtonclassName={"!bg-white"}
                              dropdownData={instructors}
                              selectedValue={instructorId}
                              getSelectedValue={(obj) => setInstructorId(obj._id)}
                              label={"Select Instructor"}
                            />
                            :
                            <Select
                              xPlacement={"left"}
                              dropdownButtonclassName={"!bg-white"}
                              dropdownData={
                                [
                                  { _id: '', label: 'Select instructor' },
                                  ...instructors
                                ]
                              }
                              selectedValue={instructorId}
                              getSelectedValue={(obj) => setInstructorId(obj._id)}
                              label={"Select Instructor"}
                            />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectTab && selectTab === "curriculum" && (
              <div className={`${!item?.current ? "hidden" : ""} `} id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                <ListLayoutAlt
                  title="Curriculums"
                  addName="Add Curriculum"
                  onAdd={() => setCurriculumsShowModal(true)}
                  limit={curriculumLimit}
                  setLimit={setCurriculumLimit}
                  setOffset={setCurriculumOffset}
                  keyword={curriculumKeyword}
                  setKeyword={setCurriculumKeyword}
                  noOfPage={Math.ceil(curriculumList.count / curriculumLimit)}
                  onPageChange={(val) => setCurriculumOffset(val.selected * curriculumLimit)}
                  totalCount={curriculumList.count}
                  setSelectedLimit={setSelectedLimit}
                >
                  <TableAlt
                    tableHeadData={tableCurriculumsHeadData}
                    tableData={curriculumList.data}
                    loading={curriculumList.loading}
                    sortField={sortField}
                    sortType={sortType}
                    setSortField={setSortField}
                    setSortType={setSortType}
                    actionData={actionCurriculumsData}
                    setList={setCurriculumList}
                    hasAction={true}
                    isDraggable={true}
                    onDrag={onDragCurriculum}
                    functions={changeCurriculumsStatus}
                  />
                </ListLayoutAlt>
              </div>
            )}
            {selectTab && selectTab === "faqs" && (
              <div className={`${!item?.current ? "hidden" : ""}`} id="faqs" role="tabpanel" aria-labelledby="faqs-tab">
                <ListLayoutAlt
                  title="FAQs"
                  addName="Add FAQs"
                  onAdd={() => setShowModal(true)}
                  limit={limit}
                  setLimit={setLimit}
                  setOffset={setOffset}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  noOfPage={Math.ceil(faqList.count / limit)}
                  onPageChange={(val) => setOffset(val.selected * limit)}
                  totalCount={faqList.count}
                >
                  <TableAlt
                    tableHeadData={tableHeadData}
                    tableData={faqList.data}
                    loading={faqList.loading}
                    sortField={sortField}
                    sortType={sortType}
                    setSortField={setSortField}
                    setSortType={setSortType}
                    actionData={actionData}
                    setList={setFaqList}
                    hasAction={true}
                    isDraggable={true}
                    onDrag={onDragFaq}
                    functions={changeStatus}
                  />
                </ListLayoutAlt>
              </div>
            )}
          </Fragment>
        ))
      }














      <AddEditFaqModal
        show={showModal}
        apiKey={tinymceApiKey()}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? "Edit FAQ" : "Add FAQ"}
        list={faqList.data}
        setList={setFaqList}
        editData={editData}
        setEditData={setEditData}
        id={id}
        langs={formatLangData}
        addType='DESC'
      />

      <AddEditCurriculumModal
        show={curriculumsShowModal}
        apiKey={tinymceApiKey()}
        setShow={setCurriculumsShowModal}
        title={Object.keys(curriculumEditData).length > 0 ? "Edit Curriculum" : "Add Curriculum"}
        list={curriculumList.data}
        setList={setCurriculumList}
        editData={curriculumEditData}
        setEditData={setCurriculumEditData}
        id={id}
        addType='DESC'
      />
    </>
  )
}

export default ManageCourse