import { Fragment, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TableSort from './TableSort';
import TableFilter from './TableFilter';
import TableDateFilter from './TableDateFilter';
import Searching from '../../Searching';
import NoDataFound from '../../NoDataFound';
import Image from '../Image';
import TableActionAlt from './TableActionAlt';
import NoImageFound from '../../../assets/no-data-found.jpg';
import { convertTimezone, formatDate } from '../../../helpers';
import { reorder } from '../../../helpers';
import { postData } from '../../../services/api';

const TableAlt = ({
  tableData = [],
  tableHeadData = [],
  loading = false,
  sortField = '',
  sortType = '',
  functions,
  hasAction = true,
  setSortField = () => { },
  setSortType = () => { },
count,
  isDraggable = false,
  actionData = () => { },
  setList = () => { },
  onDrag = () => { },
  selectedUser=[],
}) => {
  const [activeLang, setActiveLang] = useState('en')

  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData('lang/list', {})
      if (res.status === 200) {
        const defaultLang = res.data.find(item => item.default)
        if (defaultLang) {
          setActiveLang(defaultLang.code)
        }
      }
    }

    loadLangs()
  }, [])

  const onDragEnd = async (result) => {
    if (!result) return;

    const updatedList = reorder(
      tableData,
      result.source.index,
      result.destination.index
    );
    setList((list) => ({
      ...list,
      data: updatedList.map((item, i) => ({ ...item, ordering: i })),
    }));

    await onDrag(updatedList);
  };

  const status = {
    success: 'bg-green-100 text-green-600',
    danger: 'bg-rose-100 text-rose-600',
    warning: 'bg-amber-100 text-amber-600',
    info: 'bg-sky-100 text-sky-600',
    '': 'bg-slate-100 text-slate-600',
  };



  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='w-full overflow-auto scroll-smooth scrollbar'>
        <table className='min-w-max w-full table-auto'>
          <thead>
            <tr className='bg-slate-50 text-slate-700 text-xs leading-none border-b border-slate-200'>
              {/* Header items */}
              {tableHeadData.map((headItem, i) => (
                <th
                  className='py-4 px-2 text-left max-w-[240px]'
                  style={{ minWidth: headItem.width + '%' }}
                  key={i}
                >
                  <div className='flex items-center w-full justify-start'>
                    <div className='text-[11px] uppercase font-normal text-slate-400'>
                      {headItem.action ? '' : headItem.title}
                    </div>
                    {(headItem.sortable || headItem.filterable) && (
                      <div className='ml-auto flex justify-end items-center'>
                        {headItem.sortable && (
                          <TableSort
                            name={headItem.name}
                            sortType={sortType}
                            sortField={sortField}
                            setSortField={setSortField}
                            setSortType={setSortType}
                          />
                        )}
                        {
                          headItem.filterable &&
                          <TableFilter
                            xPlacement="right"
                            filterData={headItem.filterOptions || []}
                            onFilterChange={headItem.onFilterChange || (() => { })}
                          />
                        }
                        {
                          headItem.dateFilterable &&
                          <TableDateFilter
                            xPlacement='right'
                            placeholder={headItem.title}
                            onChange={headItem.onDateChange || (() => { })}
                          />
                        }
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <Droppable isDropDisabled={!isDraggable} droppableId='table-body'>
            {(provided) => (
              <tbody
                className='text-slate-700 text-sm font-light'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {!loading && tableData.length <= 0 && (
                  <tr className='border-b border-slate-200 w-full p-2'>
                    <td className='py-3 px-2 text-left' colSpan={20}>
                      <NoDataFound label={'No Data Found'} />
                    </td>
                  </tr>
                )}
                {loading && (
                  <tr className='border-b border-slate-200 w-full p-2'>
                    <td className='py-3 px-2 text-left' colSpan={20}>
                      <Searching label={'Searching'} />
                    </td>
                  </tr>
                )}
                {!loading && tableData.length > 0 && (
                  <>
                    {tableData.map((item, itemIndex) => (


                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={itemIndex}
                        isDragDisabled={!isDraggable}
                      >
                        {(provided) => (
                          <tr
                            className='border-b border-slate-200 w-full p-2'
                            key={item._id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}

                          >
                            {
                              tableHeadData.map((headerItem, i) => (
                                Object.keys(item).map((itemKey, key) => {
                                  return (
                                    <Fragment key={key}>
                                      {headerItem.name === itemKey ? (
                                        itemKey === 'status' ? (
                                          <td className='py-3 px-2 text-left max-w-[240px]' key={i} style={{ minWidth: headerItem.width + '%' }}>
                                            <div className='flex items-center'>
                                              <div
                                                className={
                                                  'text-[10px] cursor-pointer font-medium leading-tight rounded py-1 px-2 ' +
                                                  (item[itemKey]
                                                    ? status.success
                                                    : status.warning)
                                                }
                                                onClick={() => {
                                                  functions(item._id,item[itemKey])
                                                }}
                                              >
                                                {item[itemKey]
                                                  ? 'Active'
                                                  : 'Inactive'}

                                              </div>
                                            </div>
                                          </td>

                                        ) : ['created_at', 'updated_at'].includes(
                                          itemKey
                                        ) ? (
                                          <td
                                            className='py-3 px-2 text-left max-w-[240px]'
                                            key={i}
                                            style={{ minWidth: headerItem.width + '%' }}
                                          >
                                            <div className='text-sm font-normal text-slate-600'>
                                              {formatDate(
                                                convertTimezone(item[itemKey]),
                                                'Do MMM YYYY'
                                              )}
                                            </div>
                                          </td>
                                        ) : ['image', 'logo'].includes(itemKey) ? (

                                          <td key={i} className='py-3 px-2 text-left max-w-[240px]'>
                                            <div className='w-10 h-10 min-w-[40px] overflow-hidden rounded m-2'>

                                              <Image
                                                src={
                                                  item[itemKey]
                                                    ? item[itemKey]
                                                    : NoImageFound
                                                }
                                                alt={'data'}
                                                width={'100%'}
                                                height={'100%'}
                                                effect={'blur'}
                                                classes={'object-cover'}
                                              />
                                            </div>
                                          </td>
                                        ) :

                                          headerItem.dataType === 'object' ?
                                            <td
                                              className='py-3 px-2 text-left max-w-[240px]'
                                              key={i}
                                              style={{ minWidth: headerItem.width + '%' }}
                                            >
                                              <div className='text-sm font-normal text-slate-600 truncate'>
                                                {
                                                  !headerItem.langVal ?
                                                  item[itemKey][headerItem.dataKey]: item[itemKey][headerItem.dataKey][activeLang]
                                                }
                                              </div>
                                            </td> :

                                            headerItem.html === true ?
                                              (
                                                <td
                                                  className='py-3 px-2 text-left max-w-[240px]'
                                                  key={i}
                                                  style={{ minWidth: headerItem.width + '%' }}
                                                >
                                                    <div className='text-sm font-normal text-slate-600 truncate max-h-[60px]' dangerouslySetInnerHTML={{
                                                      __html: !headerItem.langVal ?
                                                        item[itemKey] :
                                                        (item[itemKey] ? item[itemKey][activeLang] : '')
                                                    }}>
                                                    </div>
                                                </td>
                                              )

                                              :
                                              itemKey === 'type' ? (
                                                <td className='py-3 px-2 text-left max-w-[240px]' key={i} style={{ minWidth: headerItem.width + '%' }}>
                                                  <div className='flex items-center'>
                                                    <div
                                                      className={
                                                        'text-[10px] font-medium leading-tight rounded py-1 px-2 ' +
                                                        (item[itemKey]===1
                                                          ? status.success
                                                          : status.info)
                                                      }
                                                      onClick={() => {
                                                        functions(item._id)
                                                      }}
                                                    >
                                                      {item[itemKey]===1
                                                        ? 'enquiry'
                                                        : 'news later'}
      
                                                    </div>
                                                  </div>
                                                </td>
      
                                              )
                                              :



                                              (
                                                <td
                                                  className='py-3 px-2 text-left max-w-[240px]'
                                                  key={i}
                                                  style={{ minWidth: headerItem.width + '%' }}
                                                >
                                                  <div className='text-sm font-normal text-slate-600 truncate'>
                                                    {
                                                      !headerItem.langVal ?
                                                        item[itemKey] :
                                                        (item[itemKey] ? item[itemKey][activeLang] : '')
                                                    }
                                                  </div>
                                                </td>
                                              )


                                      ) : (
                                        ''
                                      )}
                                      {hasAction && i === tableHeadData.length - 1 &&
                                        key === Object.keys(item).length - 1 && (
                                          <td
                                            className='py-3 px-2 text-left max-w-[240px]'
                                            style={{ minWidth: headerItem.width + '%' }}
                                          >
                                            <div className='flex items-center justify-end'>
                                               <TableActionAlt
                                                dropdownData={actionData(item)}
                                                xPlacement='right'
                                                itemId={item._id}
                                                setList={setList}
                                                count={count}
                                              />
                                            </div>
                                          </td>
                                        )}
                                    </Fragment>
                                  )
                                })
                              ))
                            }
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  </>
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </div>
    </DragDropContext>
  );
};

export default TableAlt;