import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const Image = ({src, width, height, alt, effect, classes, id=''}) => {
  const [url, setUrl] = useState(src)

  useEffect(() => {
    setUrl(src)
  }, [ src ])

  return (
    <>
      <LazyLoadImage
        src={!url ? '/no-data-found.jpg' : url}
        width={width} 
        height={height}
        alt={alt}
        effect={effect}
        className={'w-full h-full ' + (classes)}
        id={id}
        onError={() => {}}
      />
    </>
  );
};

export default Image;