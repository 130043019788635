import React, { useState } from 'react';
import { setTitle } from '../helpers/MetaTag';
import Input from '../components/form/Input';
import Button from '../components/form/Button';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { postData } from '../services/api';

const ForgotPassword = () => {
  setTitle('Rediosol | Forgot Password');
  const [email, setEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true)
    const userData = await postData('forgot-password', {
      email: email,
    });
    if (userData.status && userData.status === 200) {
      toastr.success(userData.message);
      setIsDisabled(false)
    } else {
      toastr.error(userData.message);
      setIsDisabled(false)
    }
  };

  return (
    <>
      <div className='w-full min-h-screen flex justify-center items-center bg-slate-100 py-10'>
        <div className='w-full max-w-sm'>
          <div className='h-10 w-full flex justify-center object-contain mb-5'>
            <img src='/logo-alt.png' alt='Logo' />
          </div>
          <div className='w-full bg-white rounded shadow-md py-8 px-6'>
            <div className='divide-y divide-slate-200 space-y-4'>
              <div className='relative'>
                <h1 className='text-xl font-semibold text-center text-slate-700'>
                  Forgot Password
                </h1>
                <div className='text-sm text-slate-400 text-center font-light'>
                  Please use your credentials to forgot password
                </div>
              </div>
              <div className='pt-4 space-y-4'>
                <div className='relative'>
                  <Input
                    label={'Email'}
                    labelClasses={'!text-xs'}
                    inputType={'email'}
                    inputPlaceholder={'Email address'}
                    inputValue={email}
                    setInput={setEmail}
                  />
                </div>
                <div className='relative flex justify-center'>
                  <Button
                    buttonClasses=''
                    buttonLabelClasses=''
                    buttonType={'button'}
                    buttonIcon={
                      isDisabled
                        ? 'fa-light fa-spinner fa-spin'
                        : 'fa-light fa-arrow-right-to-arc'
                    }
                    buttonIconPosition={'left'}
                    buttonLabel={'Forgot Password'}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isDisabled}
                  />
                </div>
              </div>
              <div className='flex gap-1 item-center justify-center text-slate-400 text-sm pt-4'>
                Already have a account?
                <Link to={'/signin'} className='text-teal-500 font-semibold'>
                  Signin
                </Link>
                here
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;