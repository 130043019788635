import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Table from '../components/elements/table/Table';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
// import { convertTimezone, formatDate } from '../helpers';
import toastr from 'toastr';
import AddEditEmailTempModal from '../components/modals/AddEditEmailTempModal';
import TableAlt from '../components/elements/table/TableAlt';
import { setTitle } from '../helpers/MetaTag';

const Enquiry = () => {
  setTitle("Radisol | Email Template");

  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {}
        if (keyword) {
          setOffset(0)
          setLimit(10)
          searchParams.keyword = keyword
        }

        const res = await postData('emailTemplate/list', {
          sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 } || "",
          limit: limit,
          offset: offset,
          ...searchParams
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,

          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'code',
      name: 'code',
      align: 'left',
      sortable: false,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: 'subject',
      name: 'subject',
      align: 'left',
      sortable: false,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: 'from mail',
      name: "from_mail",
      align: 'left',
      sortable: false,
      filterable: false,
    },
    {
      _id: 4,
      width: 15,
      title: 'to mail',
      name: "to_mail",
      align: 'left',
      sortable: false,
      filterable: false,
    },
    {
      _id: 5,
      width: 15,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: false,
      filterable: false,
    },
    {
      _id: 6,
      width: 15,
      title: 'status',
      name: 'status',
      align: 'left',
      sortable: false,
      filterable: false,
    },
    {
      _id: 7,
      action: true,
    },
  ];

  const changeStatus = async (id, statusValue) => {
    const res = await postData('emailTemplate/change-status', {
      id: id,
      status: !statusValue ? true : false
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,
              status: !(
                list.data.find((item) => item._id === id).status ||
                false
              ),
            }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: id => {
        setShowModal(true)
        setEditData(list.data.find(item => item._id === id) || {})
      }
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: () => changeStatus(item._id, item.status),
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: 'emailTemplate/delete',
      deletePayload: { id: item._id },
      standout: true
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData('crud/ordering', {
      table_name: 'emailTemplate',
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i }))
    })

    if (res.status !== 200) {
      toastr.error(res.message)
    }
  }


  return (
    <>
      <ListLayout
        title='Email Templates'
        addName=''
        showAddButton={false}
        onAdd={() => false}
        limit={limit}
        keyword={keyword}
        setLimit={setLimit}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          hasAction={true}
          // isDraggable={true}
          // onDrag={onDrag}
          functions={changeStatus}
        />
      </ListLayout>
      <AddEditEmailTempModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? 'Edit Email Templates' : 'Add Email Templates'}
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
        addType='DESC'
      />
    </>
  );
};

export default Enquiry;
