import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Table from '../components/elements/table/Table';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
// import { convertTimezone, formatDate } from '../helpers';
import toastr from 'toastr';
import TableAlt from '../components/elements/table/TableAlt';
import AddEditCategoryModal from '../components/modals/AddEditCategoryModal';


const Enquiry = () => {
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})

  useEffect(() => {
    if (user?._id) {

      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {}
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: 'i' }
        }
        
        const res = await postData('crud/list', {
          table_name: 'category',
          queryParams: {
            deleted_at: null,
            ...searchParams
          },
          selectParams: {
            name: true,
            status:true,
            created_at: true,
            ordering:true
          },
          sortQuery: !sortField && !sortType ? {ordering: 1} : {[sortField]:sortType},
          limit,
          offset
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'Name',
      name: 'name',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 50,
      title: 'Created Date',
      name: 'created_at',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 50,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id:4,
      action:true

    }
    
    

  ];

  const changeStatus = async (id) => {
    const res = await postData('crud/statusChange', {
      table_name: 'category',
      id: [id],
      column_name: 'status',
      column_value: !(
        list.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status ||
                  false
                ),
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: id => {
        setShowModal(true)
        setEditData(list.data.find(item => item._id === id) || {})
      }
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: changeStatus,
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: '/crud/delete',
      deletePayload: { id: [item._id], table_name: 'category' },
    },
  ];

  const onDrag = async (data = []) => {
    const res = await postData('crud/ordering', {
      table_name: 'category',
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i }))
    })

    if (res.status !== 200) {
      toastr.error(res.message)
    }   
  }

  return (
    <>
      <ListLayout
        title='Categories'
        addName='Add'
        showAddButton={true}
        onAdd={() =>setShowModal(true)}
        limit={limit}
        keyword={keyword}
        setLimit={setLimit}
        setKeyword={setKeyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          hasAction={true}
          isDraggable={true}
          onDrag={onDrag}
          functions={changeStatus}
        />
      </ListLayout>

      <AddEditCategoryModal
       title={Object.keys(editData).length > 0 ? 'Edit Category' : 'Add Category'}
       show = {showModal}
       editData = {editData}
       setEditData ={setEditData}
       setShow ={setShowModal}
       setList ={setList}
      />

    </>
  );
};

export default Enquiry;
