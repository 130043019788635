import React from "react";
import { setTitle } from "../helpers/MetaTag";

const Dashboard = () => {
  setTitle("Rediosol | Dashboard");
  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">Dashboard</div>
      </div>
      <div className="relative bg-white shadow rounded">
      </div>
    </>
  );
};

export default Dashboard;
