import { useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { postData } from "../services/api"
import toastr from "toastr"
import Input from "../components/form/Input"
import Button from "../components/form/Button"

const ResetPassword = () => {
  const navigate = useNavigate()
  const { hash } = useParams()

  const [isDisabled, setIsDisabled] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const onSubmit = async () => {
    setIsDisabled(true)

    const res = await postData('reset-admin-password', {
      password,
      confirm_password: confirmPassword,
      hash_number: hash
    })

    if (res.status === 200) {
      setIsDisabled(false)
      navigate('/')
    } else {
      setIsDisabled(false)
      toastr.error(res.message)
    }
  }
    
  return (
    <div className='w-full min-h-screen flex justify-center items-center bg-slate-100 py-10'>
        <div className='w-full max-w-sm'>
          <div className='h-10 w-full flex justify-center object-contain mb-5'>
            <img src='/logo-alt.png' alt='Logo' />
          </div>
          <div className='w-full bg-white rounded shadow-md py-8 px-6'>
            <div className='divide-y divide-slate-200 space-y-4'>
              <div className='relative'>
                <h1 className='text-xl font-semibold text-center text-slate-700'>
                  Reset Password
                </h1>
              </div>
              <div className='pt-4 space-y-4'>
                <div className='relative'>
                  <Input
                    label={'Password'}
                    labelClasses={'!text-xs'}
                    inputType={'password'}
                    inputPlaceholder={'Password'}
                    inputValue={password}
                    setInput={setPassword}
                  />
                </div>
                <div className='relative'>
                  <Input
                    label={'Confirm Password'}
                    labelClasses={'!text-xs'}
                    inputType={'password'}
                    inputPlaceholder={'Password'}
                    inputValue={confirmPassword}
                    setInput={setConfirmPassword}
                  />
                </div>
                <div className='relative flex justify-center'>
                  <Button
                    buttonClasses=''
                    buttonLabelClasses=''
                    buttonType={'button'}
                    buttonIcon={
                      isDisabled
                        ? 'fa-light fa-spinner fa-spin'
                        : 'fa-light fa-arrow-right-to-arc'
                    }
                    buttonIconPosition={'left'}
                    buttonLabel={'Forgot Password'}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ResetPassword