import { useState, useEffect } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';
import OptionsInput from '../form/OptionsInput';
import { postData } from '../../services/api';
// import { convertToBase64Async } from '../../helpers';
import toastr from 'toastr';
import TabContainer from '../elements/TabContainer';
import Button from "../form/Button";

const AddEditCourseModal = ({
  title = '',
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = 'ASC'
}) => {

  const [disabled, setDisabled] = useState()
  const [quizTitle, setQuizTitle] = useState({});
  const [quizOptions, setQuizOptions] = useState([{ option: {}, ordering: 0 }]);
  const [langs, setLangs] = useState([])
  const [selectedLangTab, setSelectedLangTab] = useState('en')

  const formatLangData = langs.map(lang => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code
  }))


  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData('lang/list', {})
      if (res.status === 200) {
        setLangs(res.data)

        const defaultLang = res.data.find(item => item.default)
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code)
        }
      }
    }

    loadLangs()
  }, [])

  useEffect(() => {
    if (editData?._id) {
      if (editData.question) {
        setQuizTitle(editData.question)
      }

      setQuizOptions(editData.answer.map(item => ({
        ...item,
        option: typeof item.option === 'string' ? { en: item.option } : item.option,
        correct: item._id === editData.correct_answer_id
      })))
    }
  }, [editData])

  const closeModal = () => {
    setShow(false);
    setQuizTitle({})
    setQuizOptions([{ option: {}, ordering: 0 }])
    setDisabled(false)
    setEditData({})
    setSelectedLangTab('en')
  };

  const onSubmit = async () => {
    setDisabled(true)

    const correctAnswer = quizOptions.find(item => item.correct)

    if (!editData?._id && !correctAnswer && quizTitle) {
      toastr.error('Correct answer is required.')
      setDisabled(false)
      return
    }

    const res = await postData(`admin/quiz/${editData?._id ? 'edit' : 'add'}`, {
      question: quizTitle,
      answer: quizOptions,
      id: editData?._id || null,
      correct_answer_id: correctAnswer ? correctAnswer._id : null,
      hasLang: true,
      langVals: ['question'],
      langArrVals: [{ val: 'answer', valKey: 'option' }],
      validationRules: {
        'data.question': 'required|nonEmptyObject',
        'data.answer': 'required|array',
        'data.correct_answer_id': !editData?._id ? 'nullable' : 'required|objectId'
      },
      validationMessages: {
        'required.data.question': 'Question Field is required',
        'nonEmptyObject.data.question': 'Question Field is required',
        'required.data.answer': 'Answer Field is required',
        'required.data.correct_answer_id': 'Correct answer is required.',
        'objectId.data.correct_answer_id': 'Correct answer has to be valid.'
      }
    })

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [res.data, ...list.data,],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : res.data)
        }))
      }

      closeModal()
      toastr.success(res.message)
    } else {
      setDisabled(false)
      toastr.error(res.message)
    }
  }

  const addInputField = () => {
    setQuizOptions(options => [...options, { option: {}, ordering: options.length - 1 }])
  }

  const handleCorrect = index => {
    if (!quizOptions[index]) {
      toastr.error('Invalid quiz.')
      return
    }

    setQuizOptions(options => options.map((item, i) => i !== index ? {
      ...item,
      correct: false
    } : {
      ...item,
      correct: true
    }))
  }

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <TabContainer
        tabs={formatLangData}
        active={selectedLangTab}
        setActive={(val) => setSelectedLangTab(val.toLowerCase())}
        changeTab={setSelectedLangTab}
      />
      <div className='relative w-full'>
        <div className="mb-3">
          <Input
            label={'Quiz Title'}
            labelClasses={'!text-xs'}
            inputType={'text'}
            inputPlaceholder={'Enter Quiz Title'}
            inputValue={quizTitle[selectedLangTab] || ''}
            setInput={(val) => setQuizTitle(data => ({
              ...data,
              [selectedLangTab]: val
            }))}
          />
        </div>
        {
          quizOptions?.map((item, i) => (
            <div className='mb-3' key={i}>
              <OptionsInput
                label={`Quiz Options ${i + 1}`}
                labelClasses={'!text-xs'}
                inputType={'text'}
                inputPlaceholder={'Enter Quiz Options'}
                inputValue={item?.option[selectedLangTab] || ''}
                setInput={(value) => {
                  setQuizOptions(options => options.map((item, index) => index !== i ? item : {
                    ...item,
                    option: { ...item.option, [selectedLangTab]: value }
                  }))
                }}
                correct={item.correct || false}
                functions={() => {
                  setQuizOptions(options => options.filter((item, index) => {
                    if (options.length === 1) return true

                    return index !== i
                  }))
                }}
                field={quizOptions}
                index={i}
                onCorrect={handleCorrect}
              />
            </div>
          ))
        }


        <div className='flex justify-center'>
          <Button
            buttonClasses={"!h-6 !bg-transparent !text-teal-500"}
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={"Add more options"}
            buttonHasLink={false}
            functions={addInputField}
          />
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditCourseModal;
