import React from 'react'

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Table from '../components/elements/table/Table';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
// import { convertTimezone, formatDate } from '../helpers';
import toastr from 'toastr';
import { API_URL } from '../config/host';
import { setTitle } from '../helpers/MetaTag';
import ActivityItem from '../components/pagesection/Activity/ActivityItem';

const Activity = () => {
  setTitle('Radisol | Activity');
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [dateRange, setDateRange] = useState({});
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [userList, setUserList] = useState({
    loading: true,
    data: [],
    count: 0,
  });


  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: 'Active', type: 'checkbox', value: true, isChecked: false },
    { _id: 2, name: 'Inactive', type: 'checkbox', value: false, isChecked: false },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          setOffset(0)
          setLimit(10)
          searchParams.keyword = keyword;
        }

        if (dateRange && dateRange.start && dateRange.end) {
          searchParams.dateRange = {
            start: dateRange.start, end: dateRange.end
          }
        }


        const checkedStatusFilter = statusFilterData.filter((id) => id.isChecked)
        if (checkedStatusFilter.length > 0) {
          searchParams.status = checkedStatusFilter.map((item) => item.value)
        }

        const res = await postData('activity/list', {
          ...searchParams,
          sortByField: sortField,
          sortByValue: sortType,
          limit: limit,
          offset: offset,
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType, dateRange, statusFilterData]);





  return (
    <>
      <ListLayout
        title='Activities'
        addName='Add Activities'
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        setOffset={setOffset}
        showAddButton={false}
      />
      <ActivityItem
        data={list}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
      />
    </>
  );
};

export default Activity;
