import { useState, useEffect, useRef, Fragment } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';
// import Textarea from '../form/Textarea';
import Image from '../elements/Image';
import ButtonFile from '../form/ButtonFile';
import { postData } from '../../services/api';
// import { convertToBase64Async } from '../../helpers';
import toastr from 'toastr';
import { Editor } from '@tinymce/tinymce-react';
import { tinyMceInit, tinymceApiKey } from '../../helpers';
import TabContainer from '../elements/TabContainer';
import { validateURL } from '../../helpers';
import Button from "../form/Button";

const AddEditInstructorModal = ({
  title = '',
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = 'ASC',
}) => {
  const editorRef = useRef(null);

  const [disabled, setDisabled] = useState();
  const [name, setName] = useState('');
  const [designation, setDesignation] = useState({});
  const [socialLink, setSocialLink] = useState([]);
  const [websiteLink, setWebsiteLink] = useState('');
  const [emailLink, setEmailLink] = useState('');
  const [description, setDescription] = useState({});
  const [image, setImage] = useState(null);
  const [socialName, setSocialName] = useState([]);
  const [langs, setLangs] = useState([]);
  const [selectedLangTab, setSelectedLangTab] = useState('en');


  useEffect(() => {
    const loadLangs = async () => {
      const res = await postData('lang/list', {});
      if (res.status === 200) {
        setLangs(res.data);

        const defaultLang = res.data.find((item) => item.default);
        if (defaultLang) {
          setSelectedLangTab(defaultLang.code);
        }
      }
    };

    loadLangs();
  }, []);

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      if (editData.designation) {
        typeof editData.designation === 'string' ? setDesignation({
          en: editData.designation
        }) : setDesignation(editData.designation)
      }
      if (editData.description) {
        typeof editData.description === 'string' ? setDescription({
          en: editData.description
        }) : setDescription(editData.description);
      }
      setWebsiteLink(editData.website);
      setEmailLink(editData.email);
      setImage(editData.image);
      setSocialLink(editData.social_links);
    }
  }, [editData]);


  const closeModal = () => {
    setShow(false);
    setName('');
    setDesignation({});
    setDescription({});
    setImage(null);
    setEmailLink('');
    setSocialLink([]);
    setWebsiteLink('');
    setDisabled(false);
    setEditData({});
    setSelectedLangTab('en')
  };

  const onImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml"];



      if (!allowedImageTypes.includes(file.type)) {
        toastr.error("File is not a valid image.");
        return;
      }
      setImage(file);
    }
  };

  const onSubmit = async () => {
    setDisabled(true);
    let imageId = undefined;
    const formData = new FormData();

    const docs = {
      name: name,
      email: emailLink,
      description:description,
      designation:designation,
      social_links: socialLink,
      website: websiteLink,
      id: editData?._id || null,
      image_id: imageId,
      ordering: editData?._id ? editData.ordering : list.length,
      hasLang: true,
    }

    // if (Object.keys(description).length > 0) {
    //   docs['description'] = description
    // }
    // if (Object.keys(designation).length > 0) {
    //   docs['designation'] = designation
    // }

    const jsonData = JSON.stringify(docs);

    if (image instanceof File) {
      formData.append('file', image);
    }

    formData.append("document", jsonData);

    if (socialLink.length) {
      const inivalidUrls = socialLink.filter(item => (
        item.link && !validateURL(item.link)
      ))

      if (inivalidUrls.length) {
        toastr.error('Invalid URL string, please add http or https.')
        setDisabled(false);
        return
      }
    }

    const res = await postData(`instructor/${editData?._id ? 'edit' : 'create'}`, formData, "multipart/form-data");
    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };



  useEffect(() => {
    const loadSubCategories = async () => {
      const res = await postData('crud/list', {
        table_name: 'social',
        queryParams: {
          deleted_at: null,
        },
        selectParams: {
          name: true,
          code: true,
          icon: true,
        },
      });
      let subcat = [];

      Array.isArray(res.data.list) &&
        res.data.list.forEach((item, index) => {
          subcat.push({
            _id: item._id,
            label: item.name,
            code: item.code,
            icon: item.icon,
            value: item._id,
          });
        });
      setSocialName(subcat);
    };
    loadSubCategories();
  }, []);

  const formatLangData = langs.map((lang) => ({
    _id: lang._id,
    label: lang.code.toUpperCase(),
    icon: "fa-language",
    link: "/",
    type: "button",
    current: selectedLangTab && selectedLangTab === lang.code,
    code: lang.code,
  }));

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize='xxxxl'
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <TabContainer
        tabs={formatLangData}
        changeTab={setSelectedLangTab}
        setActive={(val) => setSelectedLangTab(val.toLowerCase())}
        active={selectedLangTab}
      />
      <div className='flex justify-between w-full'>
        <div className='relative w-full'>
          <div className='relative mb-3'>
            <Input
              label={'Instructor Name'}
              labelClasses={'!text-xs'}
              inputType={'text'}
              inputPlaceholder={'Enter Instructor Name'}
              inputValue={name}
              setInput={setName}
            />
          </div>
          <div className='relative mb-3'>
            <Input
              label={'Instructor Designation'}
              labelClasses={'!text-xs'}
              inputType={'text'}
              inputPlaceholder={'Enter Instructor Designation'}
              inputValue={designation[selectedLangTab] || ''}
              setInput={
                (designation?.[selectedLangTab] === "") ? setDesignation({}) :
                (val) => setDesignation(data => ({
                ...data,
                [selectedLangTab]: val
              }))}
            />
          </div>

          <div className="grid sm:grid-cols-2 gap-x-4">
            <div className='relative mb-3'>
              <Input
                label={'Add Email'}
                labelClasses={'!text-xs'}
                inputType={'text'}
                inputPlaceholder={'Enter Email Id'}
                inputValue={emailLink}
                setInput={setEmailLink}
              />
            </div>
            <div className='relative mb-3'>
              <Input
                label={'Add Website'}
                labelClasses={'!text-xs'}
                inputType={'text'}
                inputPlaceholder={'Enter Website Link'}
                inputValue={websiteLink}
                setInput={setWebsiteLink}
              />
            </div>
          </div>

          <div className='relative mb-3'>
            <Editor
              apiKey={tinymceApiKey()}
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={description[selectedLangTab] || ''}
              init={{ height: 400, }}
              // onEditorChange={(newText) => setDescription(data => ({
              //   ...data,
              //   [selectedLangTab]: newText
              // }))}
              onEditorChange={
                (description?.[selectedLangTab] === "") ? setDescription({}) : (newText) => setDescription(data => ({
                  ...data,
                  [selectedLangTab]: newText
                }))
              }
            />
            {/* <Textarea
                    label={'About Instructor'}
                    labelClasses={'!text-xs'}
                    inputType={'text'}
                    inputPlaceholder={'Enter About Instructor'}
                    inputValue={description}
                    setTextarea={setDescription}
                /> */}
          </div>
        </div>

        <div className='w-[550px] ml-4 pl-4 border-l border-solid border-gray-200'>
          <div className="relative mb-3">
            {image && (
              <div className="relative w-full rounded border border-slate-200 flex overflow-hidden mb-3">
                <Button
                  buttonClasses={"!absolute !z-10 !top-1 !right-1 !cursor-pointer !bg-white !text-rose-500 !w-6 !h-6 !rounded-full !shadow !text-sm"}
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <Image
                  src={
                    image instanceof File ? URL.createObjectURL(image) : image
                  }
                  alt={name}
                  width={'100%'}
                  height={'100%'}
                  effect={'blur'}
                  classes={'object-cover'}
                />
              </div>
            )}
            <div className="relative mb-3">
              <ButtonFile
                functions={onImageChange}
                buttonLabel='Upload Image'
                buttonIconPosition='left'
                buttonIcon='fa-arrow-up-from-bracket'
              />
            </div>
          </div>

          {socialName?.map((input) => {
            return (
              <Fragment key={input._id}>
                <div className='mb-3'>
                  <Input
                    label={`${input.label} Link`}
                    labelClasses={'!text-xs'}
                    inputType={'text'}
                    inputPlaceholder={'Enter Social Media Link'}
                    inputValue={
                      socialLink.find((link) => link.social_id === input._id)
                        ?.link || ''
                    }
                    setInput={(value) => {
                      setSocialLink((links) => {
                        const existingLink = links.find(
                          (link) => link.social_id === input._id
                        );

                        if (!existingLink) {
                          return [
                            ...links,
                            {
                              social_id: input._id,
                              link: value,
                            },
                          ];
                        } else {
                          return links.map((item) =>
                            item.social_id !== input._id
                              ? item
                              : {
                                ...item,
                                social_id: input._id,
                                link: value,
                              }
                          );
                        }
                      });
                    }}
                  />
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditInstructorModal;
