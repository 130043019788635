import React, { useEffect, useState } from 'react';
import TabContainer from "../components/elements/TabContainer";
import TableAlt from "../components/elements/table/TableAlt";
import Button from "../components/form/Button";
import Search from "../components/form/Search";
import Select from "../components/form/Select";
import ListLayoutAlt from "../containers/ListLayoutAlt";
import { useParams } from 'react-router-dom';
import { postData } from '../services/api';

const UserDetails = () => {
  const { id } = useParams()
  const [active, setActive] = useState("Courses");
  const [selectTab, setSelectTab] = useState("courses");

  const changeTab = async (code) => {
    setSelectTab(code);
  };

  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [dateRange, setDateRange] = useState({})
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });

  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const tabData = [
    {
      _id: 1,
      label: "Courses",
      icon: "fa-book",
      link: "/",
      type: "button",
      current: selectTab && selectTab === "courses" ? true : false,
      code: "courses",
    },
    {
      _id: 2,
      label: "Quiz Results",
      icon: "fa-trophy",
      link: "/",
      type: "button",
      current: selectTab && selectTab === "quiz-results" ? true : false,
      code: "quiz-results",
    },
  ];

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'Title',
      name: 'title',
      align: 'left',
      sortable: true,
      filterable: false,
      langVal: true
    },
    {
      _id: 3,
      width: 15,
      title: 'Short Description',
      name: 'sub_title',
      align: 'left',
      sortable: true,
      filterable: false,
      langVal: true
    },
    {
      _id: 5,
      width: 15,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: true,
      dateFilterable: true,
    },
    {
      _id: 4,
      width: 15,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 6,
      title: "Action",
      action: true,
    },
  ];

  const tableHeadDataQuiz = [
    {
      _id: 1,
      width: 20,
      title: 'User',
      name: 'user_name',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 20,
      title: 'Email',
      name: 'user_email',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 20,
      title: 'Company',
      name: 'company_name',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      width: 20,
      title: 'company Email',
      name: 'company_email',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 30,
      title: 'course',
      name: 'course_title',
      align: 'left',
      sortable: true,
      filterable: false,
      langVal: true,
      // dataType: "object",
      // dataKey: "en",
    },
    {
      _id: 4,
      width: 10,
      title: 'Total Answers',
      name: 'totalAnswers',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      width: 10,
      title: 'Pass Mark',
      name: 'pass_mark',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 6,
      width: 10,
      title: 'Percentage',
      name: 'percentage',
      align: 'left',
      sortable: true,
      filterable: false,
    },
  ];

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'link',
      link: '/edit-course'
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: '/crud/delete',
      deletePayload: { id: [item._id], table_name: 'course' },
    },
  ];

  // user list hitting===========>


  useEffect(() => {
    const loadData = async () => {
      setList((list) => ({ ...list, loading: true }));
      const res = await postData(`${(selectTab === "courses") ? "course/list" : "course/quiz/results"}`, {
        userId: id,
        sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        limit: limit,
        offset: offset,
      });
      if (res.status === 200) {
        console.log(res, "admin userdetails")
        setList((list) => ({
          ...list,
          data: res.data,
          loading: false,
          count: res.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }
    };

    loadData();
  }, [selectTab]);





  return (
    <>
      <div className="relative d-flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">User Details</div>
      </div>
      <div className="relative">
        <TabContainer active={active} changeTab={changeTab} setActive={setActive} tabs={tabData} />
        {active === "Courses" &&
          <>
            <ListLayoutAlt
              showAddButton={true}
              addName='Add Courses'
              onAdd={() => setShowModal(true)}
              limit={limit}
              setLimit={setLimit}
              keyword={keyword}
              setKeyword={setKeyword}
              noOfPage={Math.ceil(list.count / limit)}
              onPageChange={(val) => setOffset(val.selected * limit)}
            >
              <TableAlt
                tableHeadData={tableHeadData}
                tableData={list.data}
                loading={list.loading}
                sortField={sortField}
                sortType={sortType}
                setSortField={setSortField}
                setSortType={setSortType}
                actionData={actionData}
                setList={setList}
                hasAction={true}
                isDraggable={false}
              />
            </ListLayoutAlt>
          </>
        }
        {active === "Quiz Results" &&
          <>
            <ListLayoutAlt
              showAddButton={false}
              limit={limit}
              setLimit={setLimit}
              noOfPage={Math.ceil(list.count / limit)}
              onPageChange={(val) => setOffset(val.selected * limit)}
            >
              <TableAlt
                tableHeadData={tableHeadDataQuiz}
                tableData={list.data}
                loading={list.loading}
                sortField={sortField}
                sortType={sortType}
                setSortField={setSortField}
                setSortType={setSortType}
                actionData={actionData}
                setList={setList}
                hasAction={true}
                isDraggable={false}
              />
            </ListLayoutAlt>
          </>
        }
      </div>

    </>
  );
};

export default UserDetails;