import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Table from '../components/elements/table/Table';
import TableAlt from '../components/elements/table/TableAlt';
import ListLayout from '../containers/ListLayout';
import { postData } from '../services/api';
// import { convertTimezone, formatDate } from '../helpers';
import toastr from 'toastr';
import AddEditTagsModal from '../components/modals/AddEditTagsModal';
import { setTitle } from '../helpers/MetaTag';

const Tags = () => {
  setTitle("Radisol | Tags");

  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [tags, setTags] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false)
  const [editData, setEditData] = useState({})

  useEffect(() => {
    if (user?._id) {
      const loadData = async () => {
        setTags((cats) => ({ ...cats, loading: true }));
        const searchParams = {}
        if (keyword) {
          searchParams.tag_name = { $regex: keyword, $options: 'i' }
        }

        const res = await postData('crud/list', {
          table_name: "tag",
          queryParams: {
            deleted_at: null,
            ...searchParams
          },
          selectParams: {
            tag_name: true,
            status: true,
            deleted_at: true,
            created_at: true,
            ordering:true
          },
          sortQuery: !sortField && !sortType ? {ordering:1} : {[sortField]:sortType},
          limit,
          offset
        });
        if (res.status === 200) {
          setTags((cats) => ({
            ...cats,
            data: res.data.list,
            loading: false,
            count: res.data.count,
          }));
        } else {
          setTags((cats) => ({ ...cats, loading: false }));
        }
      };

      loadData();
    }
  }, [user?._id, keyword, limit, offset, sortField, sortType]);


  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: 'Name',
      name: 'tag_name',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: 'Created At',
      name: 'created_at',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: 'Status',
      name: 'status',
      align: 'left',
      sortable: true,
      filterable: false,
    },
    {
      _id: 4,
      action: true,
    },
  ];

  const changeStatus = async (id) => {
    const res = await postData('crud/statusChange', {
      table_name: "tag",
      id: [id],
      column_name: 'status',
      column_value: !(
        tags.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setTags((tags) => ({
        ...tags,
        data: tags.data.map((item) =>
          item._id !== id
            ? item
            : {
              ...item,
              status: !(
                tags.data.find((item) => item._id === id).status ||
                false
              ),
            }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: 'Edit',
      icon: 'fa-pen-to-square',
      type: 'button',
      onClick: id => {
        setShowModal(true)
        setEditData(tags.data.find(item => item._id === id) || {})
      }
    },
    {
      name: item.status ? 'Inactive' : 'Active',
      icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
      type: 'button',
      onClick: changeStatus,
    },
    {
      name: 'Delete',
      icon: 'fa-trash-can',
      type: 'button',
      actionType: 'delete',
      deleteLink: 'crud/delete',
      deletePayload: { id: [item._id], table_name: "tag" },
    },
  ];



  const onDrag = async (data = []) => {
    const res = await postData('crud/ordering', {
      table_name: "tag",
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i }))
    })

    if (res.status !== 200) {
      toastr.error(res.message)
    }else{
      toastr.success(res.message)
    }
  }

  return (
    <>
      <ListLayout
        title='Tags'
        addName='Add Tags'
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(tags.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={tags.data}
          loading={tags.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setTags}
          isDraggable={true}
          onDrag={onDrag}
          functions={changeStatus}
        />
      </ListLayout>
      <AddEditTagsModal
        show={showModal}
        setShow={setShowModal}
        title={Object.keys(editData).length > 0 ? 'Edit Tag' : 'Add Tag'}
        list={tags}
        setList={setTags}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
};

export default Tags;
