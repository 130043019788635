import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyList: [],
  userList: [],
  courseList: [],
  tabChange: true
}

const companiesSlice = createSlice({
  name: "companyList",
  initialState,
  reducers: {
    listData: (state, action) => {
      state.companyList = action.payload
    },
    userListData: (state, action) => {
      state.userList = action.payload
    },
    courseListData: (state, action) => {
      state.courseList = action.payload
    },
    userTabChange: (state, action) => {
      state.tabChange = !state.tabChange
    }
  }
})

export const { listData, userListData, userTabChange,courseListData } = companiesSlice.actions
export default companiesSlice.reducer