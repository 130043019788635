import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Checkbox from "../components/form/Checkbox";
import Input from "../components/form/Input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { postData } from "../services/api";
import { authLogin } from "../store/authSlice";
import { Link } from 'react-router-dom';
import toastr from "toastr";

const Login = () => {
  setTitle("Rediosol | Login");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLogin(true);
    try {
      const userData = await postData("login", {
        login_type: 'email',
        email: email,
        password: password,
      });
      if (userData.status && userData.status === 200) {
        setIsLogin(false);
        dispatch(authLogin(userData.data));
        localStorage.setItem("accessToken", userData?.accessToken);
        localStorage.setItem("role", userData?.data?.code);
        toastr.success(userData.message);
        return navigate("/courses");
      } else {
        toastr.error(userData.message);
        setIsLogin(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsLogin(false);
    }
  };

  return (
    <>
      <div className="w-full min-h-screen flex justify-center items-center bg-slate-100 py-10">
        <div className="w-full max-w-sm">
          <div className="h-10 w-full flex justify-center object-contain mb-5">
            <img src={require('../assets/logo.png')}/>
          </div>
          {/* <div className="w-full mb-5 text-center space-y-2">
            <div className="text-slate-500 text-xl font-extralight leading-none">President/Chief Executive Officer Succession Plan</div>
            <div className="text-sm text-slate-900 font-bold">Yankee Federal Credit Union</div>
          </div> */}
          <div className="w-full bg-white rounded shadow-md py-8 px-6">
            <div className="divide-y divide-slate-200 space-y-4">
              <div className="relative">
                <h1 className="text-xl font-semibold text-center text-slate-700">
                  Signin
                </h1>
                <div className="text-sm text-slate-400 text-center font-light">
                  Please use your credentials to signin
                </div>
              </div>
              <form className="pt-4 space-y-4">
                <div className="relative">
                  <Input
                    label={"Email"}
                    labelClasses={"!text-xs"}
                    inputType={"email"}
                    inputPlaceholder={"Email address"}
                    inputValue={email}
                    setInput={setEmail}
                    functions={onSubmit}
                    //errorType={'error'}
                    //errorText={}
                  />
                </div>
                <div className="relative">
                  <Input
                    label={"Password"}
                    labelClasses={"!text-xs"}
                    inputType={"password"}
                    inputPlaceholder={"Your Password"}
                    inputValue={password}
                    setInput={setPassword}
                    functions={onSubmit}
                    //errorType={''}
                  />
                </div>
                
                <div className="relative flex justify-center">
                  <Button
                    buttonClasses=""
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={
                      isLogin
                        ? "fa-light fa-spinner fa-spin"
                        : "fa-light fa-arrow-right-to-arc"
                    }
                    buttonIconPosition={"left"}
                    buttonLabel={"Signin"}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isLogin}
                    //buttonLink={'/dashboard'}
                  />
                </div>
                <div className="relative flex items-center justify-center">
                  {/* <div className="">
                    <Checkbox
                      checkboxName={"remember_me"}
                      isChecked={false}
                      checkboxLabel={"Remember Me"}
                    />
                  </div> */}
                  <div className="">
                    <Link to={"/forgot-password"} className="text-teal-500 font-medium text-sm">Forgot Password</Link>
                  </div>
                </div>
              </form>
              {/* <div className="flex gap-1 item-center justify-center text-slate-400 text-sm pt-4">
                Dont' have a account?
                <Link to={"/signup"} className="text-teal-500 font-semibold">Signup</Link>
                here
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
