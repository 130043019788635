import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({
  buttonClasses, 
  buttonType, 
  buttonIcon, 
  buttonIconPosition, 
  buttonLabel, 
  buttonLabelClasses, 
  buttonHasLink,
  buttonLink,
  functions,
  buttonDisabled,
  ...props
}) => {
  
  return (
    <>
      { buttonHasLink?
        (
          <Link to={buttonLink}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 " + (buttonClasses)}
          >
            {buttonIconPosition === 'left' &&
            <i className={"fa-fw " + (buttonIcon)}></i>
            }
            {buttonLabel &&
            <span className={"text-sm font-medium " + (buttonLabelClasses)}>{buttonLabel}</span>
            }
            {buttonIconPosition === 'right' &&
            <i className={"fa-fw " + (buttonIcon)}></i>
            }
          </Link>
        ):
        (
          <button 
            type={buttonType} 
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer " + (buttonClasses)} 
            onClick={functions}
            disabled={buttonDisabled}
          >
            {buttonIconPosition === 'left' &&
            <i className={"fa-fw " + (buttonIcon)}></i>
            }
            {buttonLabel &&
            <span className={"text-sm font-medium " + (buttonLabelClasses)}>{buttonLabel}</span>
            }
            {buttonIconPosition === 'right' &&
            <i className={"fa-fw " + (buttonIcon)}></i>
            }
          </button>
        )
      }
    </>
  );
};

export default Button;