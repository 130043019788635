import { useEffect } from "react";

export default function useOnClickOutside(ref, handler = () => {}, altRef) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref || !ref.current || ref.current.contains(event.target)) {
        return;
      }

      if (altRef && altRef.current && altRef.current.contains(event.target)) {
        return;
      }

      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler, altRef]);
}