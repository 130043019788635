import { useState, useEffect, useRef } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';
// import Textarea from '../form/Textarea';
import { postData } from '../../services/api';
// import { convertToBase64Async } from '../../helpers';
import toastr from 'toastr';
import { Editor } from '@tinymce/tinymce-react';
import TabContainer from '../elements/TabContainer';
import { decodeHTML } from '../../helpers';

const AddEditFaqModal = ({
  title = '',
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = 'ASC',
  id,
  apiKey,
  langs = []
}) => {
  const editorRef = useRef(null);
  const [disabled, setDisabled] = useState()
  const [question, setQuestion] = useState({});
  const [answer, setAnswer] = useState({});
  const [image,] = useState(null)
  const [selectedLangTab, setSelectedLangTab] = useState('en')

  useEffect(() => {
    if (editData?._id) {
      if (editData.question) {
        typeof editData.question === 'string' ? setQuestion({
          en: editData.question
        }) : setQuestion(editData.question)
      }

      if (editData.answer) {
        typeof editData.answer === 'string' ? setAnswer({ en: editData.answer }) : setAnswer(editData.answer)
      }
    }
  }, [editData])

  const closeModal = () => {
    setShow(false);
    setQuestion({})
    setAnswer({})
    setDisabled(false)
    setEditData({})
    setSelectedLangTab('en')
  };

  /* const onImageChange = e => {
    if (e.target.files.length) {
      setImage(e.target.files[0])
    }
  } */

  const onSubmit = async () => {
    setDisabled(true)

    // let base64 = ''

    if (image) {
      // base64 = await convertToBase64Async(image)
    }

    const res = await postData(`faq/${editData?._id ? 'edit' : 'add'}`, {
      "question": question,
      "answer": answer,
      "course_id": id,
      id: editData?._id || null,
    })
    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList(list => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1
          }))
        } else {
          setList(list => ({
            ...list,
            data: [res.data, ...list.data,],
            count: list.count + 1
          }))
        }
      } else if (editData && editData?._id) {
        setList(list => ({
          ...list,
          data: list.data.map(item => item._id !== editData._id ? item : res.data)
        }))
      }

      closeModal()
      toastr.success(res.message)
    } else {
      setDisabled(false)
      toastr.error(res.message)
    }
  }

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <TabContainer
        active={selectedLangTab}
        changeTab={setSelectedLangTab}
        tabs={langs}
        setActive={(val) => setSelectedLangTab(val.toLowerCase())}
      />
      <div className='flex justify-between w-full'>
        <div className='relative w-full'>
          <div className="mb-3">
            <Input
              label={'Faq Question'}
              labelClasses={'!text-xs'}
              inputType={'text'}
              inputPlaceholder={'Enter Faq Question'}
              inputValue={question[selectedLangTab] || ''}
              setInput={(val) => setQuestion(data => ({
                ...data,
                [selectedLangTab]: val
              }))}
            />
          </div>
          <label className='text-xs font-medium text-slate-500 mb-1 mt-3'>Faq Answer</label>
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            value={decodeHTML(answer[selectedLangTab]) || ''}
            init={{
              valid_elements: '+*[*]',
              height: 200,
              menubar: false,
              plugins: [
                'code',
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'code | undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help ',
            }}
            onEditorChange={(newText) => setAnswer(data => ({
              ...data,
              [selectedLangTab]: newText
            }))}
          />
        </div>

      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditFaqModal;
