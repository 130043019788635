import moment from 'moment/moment';
import { API_URL } from '../config/host';
// import { postData } from '../services/api';

export function allowedExtensions() {
  const ext = ['jpg', 'jpeg', 'png', 'pdf'];
  return ext;
}

export function allowedImageExtensions() {
  const ext = [
    "jpg",
    "jpeg",
    "png",
    "bmp",
    "gif",
    "svg",
    "webp",
    "ico",
    "jfif",
    'heic'
  ];
  return ext;
}

export function dayRemaining(date) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date();
  const secondDate = new Date(date);
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays + (diffDays > 1 ? ' days' : ' day');
}

export function sumTotal(data, field) {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    sum = sum + parseFloat(data[i][field] ? data[i][field] : 0);
  }
  return sum;
}

export function doesFileExist(file) {
  try {
    let fullPath = API_URL + file;
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', fullPath, false);
    xhr.send();
    if (xhr.readyState === 4 && xhr.status === 404) {
      return { status: 400, path: './no-data-found.jpg' };
    } else {
      return { status: 200, path: fullPath };
    }
  } catch (err) {
    return { status: 400, path: './no-data-found.jpg' };
  }
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const tinymceApiKey = () => {
  const key = 'hu65guu8gs1a6iw36ksmd9uo0cd0rtxjvlacpdz5w2igzf49';
  return key;
};

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const convertTimezone = (date, timeZone = browserTimezone) => {
  if (!date) return '';

  return new Date(date).toLocaleString('en-US', { timeZone });
};

export const formatDate = (date, formatString) => {
  if (!date) return '';

  return moment(new Date(date)).format(formatString);
};

export const allowedImgExts = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'webp', 'ico', 'jfif'];

export const convertToBase64Async = file => new Promise((res, rej) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => res(reader.result);
  reader.onerror = error => rej(error);
})

export function tinyMceInit() {
  const init = {
    valid_elements: '+*[*]',
    height: 400,
    menubar: false,
    selector: 'textarea',
    plugins: [
      'code',
      'lists',
      'link',
      'image',
      'charmap',
      'print',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'fullscreen',
      'insertdatetime',
      'media',
      'paste',
      'help',
      'wordcount',

    ],
    toolbar: 'code | undo redo | formatselect | ' +
      'bold italic backcolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat | help ',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    lists_indent_on_tab: false
  }
  return init;
}

export function blobToImage(blob) {
  return new Promise(resolve => {
    const url = URL.createObjectURL(blob)
    let img = new Image()
    img.onload = () => {
      URL.revokeObjectURL(url)
      resolve(img)
    }
    img.src = url
  })
}

// export const convertImageUrlToBlob = async file_id => {
//   const result = await postData('file-get',{id: file_id});  
//   const response = await fetch(result?.path);
//   console.log('response',response)
//   const data = await response.blob();
//   const file = new File([data], result.type, data);
//   return file || null
// }

// export const filterDataChange = (key = '', value, array = [], setArray) => {
//   const allItems = array

//   if (array.includes(value)) {

//   }
// }

export const decodeHTML = (str = '') => {
  let txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
};

export const validateURL = (value = '') => {
  if (!value) return false

  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
}