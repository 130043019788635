import React from 'react'
import { formatName } from '../../../helpers/formatName'
import moment from 'moment/moment'
import ReactPaginate from 'react-paginate'
import NoDataFound from '../../NoDataFound'
import Searching from '../../Searching'

const ActivityItem = ({ data, ...props }) => {
  return (
    <>
      {data?.loading && (
        <div>
          <Searching label={'Searching'} />
        </div>
      )}
      {!data?.loading && data?.data.length <= 0 && (
        <div>
          <NoDataFound label={'No Data Found'} />
        </div>
      )}
      {
        !data?.loading && data?.data.length > 0 && data?.data.map((list) =>
          <div className='list bg-white shadow-sm p-3 rounded-md flex items-center mb-2 hover:translate-x-1 transition-all hover:shadow-md cursor-pointer'>
            <div className='h-10 w-10 mr-2 bg-teal-300 rounded-full text-white flex items-center justify-center'>
              {
                list?.user?.name === null || list?.user === null ? "RU" : formatName(list?.user?.name)
              }
            </div>
            <div>
              <span className='capitalize text-teal-400 font-semibold'>{list?.user?.name}</span>
              {
                (list.type === "LOGIN") && <span>&nbsp;<span className='lowercase text-gray-500'>logged on</span>&nbsp;<span className='text-teal-400 font-semibold'>{moment(list.created_at).format("MMM Do YY")} <span className='lowercase text-gray-500'>at</span> {moment(list.created_at).format("h:mm:ss a")}</span></span>
              }
              {
                (list.type === "EMAIL_CHANGED") && <span>&nbsp;<span className='lowercase text-gray-500'>email changed on</span>&nbsp;<span className='text-teal-400 font-semibold'>{moment(list.created_at).format("MMM Do YY")} <span className='lowercase text-gray-500'>at</span> {moment(list.created_at).format("h:mm:ss a")}</span></span>
              }
            </div>
          </div>
        )
      }
      {
        props.noOfPage > 1 &&
        <div className='flex justify-end my-4'>
          <ReactPaginate
            breakLabel='...'
            breakClassName='w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200'
            nextLabel={<i className='fa-regular fa-fw fa-chevron-right'></i>}
            nextClassName='w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200'
            onPageChange={props.onPageChange || (() => { })}
            pageRangeDisplayed={3}
            pageCount={props.noOfPage}
            containerClassName='flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2'
            activeClassName='hover:bg-teal-500 bg-teal-500 text-white'
            pageLinkClassName='flex justify-center items-center w-full h-full'
            previousLabel={<i className='fa-regular fa-fw fa-chevron-left'></i>}
            pageClassName='w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200'
            previousClassName='w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200'
            renderOnZeroPageCount={null}
          />
        </div>
      }
    </>
  )
}

export default ActivityItem