import { useState, useEffect } from "react";
import Input from "../form/Input";

import { postData } from "../../services/api";
import Textarea from "../form/Textarea";

import toastr from "toastr";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Image from "../elements/Image";

import ButtonFile from "../form/ButtonFile";

import { API_URL } from "../../config/host";

const AddEditCourseGuide = ({ title = "", show = false, editData = {}, setEditData = () => { }, setShow = () => { }, list = [], setList = () => { }, addType = "ASC" }) => {
  const [disabled, setDisabled] = useState();

  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setDescription(editData.description);
      setImage(editData.image);
    }

  }, [editData])


  const closeModal = () => {
    setShow(false);
    setName("");
    setDescription("");
    setImage(null);

    setDisabled(false);
    setEditData({});
  };
  const onImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpg", "image/jpeg", "image/png","image/svg+xml"];



      if (!allowedImageTypes.includes(file.type)) {
        toastr.error("File is not a valid image.");
        return;
      }
      setImage(file);
    }
  };

  const onSubmit = async () => {
    setDisabled(true);
    let imageId = undefined;
    if (image instanceof File) {
      const formData = new FormData();
      formData.append("file", image);
      const imgRes = await postData("file/upload", formData, "multipart/form-data");
      if (imgRes.status === 200) {
        imageId = imgRes.data?._id;
      } else {
        setDisabled(false);
        toastr.error(imgRes.message);
      }
    }

    const res = await postData(`crud/${editData?._id ? "edit" : "add"}`, {
      table_name: "courseGuide",

      data: {
        name: name,
        description: description,
        image_id: imageId,
        id: editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.length,
      },
      validationRules: {
        "data.name": "required",
        "data.description": "required",
      },
      validationMessages: {
        "required.data.name": "name Field is required",
        "required.data.description": "Description Field is required",
      },
    });

    if (res.status === 200) {
      let detailsRes = await postData("crud/list", {
        table_name: "courseGuide",
        queryParams: {
          deleted_at: null,
          _id: res.data._id,
        },
        selectParams: {
          description: true,
          status: true,
          name: true,
          created_by: true,
          ordering: true,
          image: {
            $cond: [
              { $arrayElemAt: ["$image.path", 0] },
              {
                $concat: [API_URL, { $arrayElemAt: ["$image.path", 0] }],
              },
              null,
            ],
          },
        },
        join: [
          {
            toTable: "files",
            fromField: "image_id",
            toField: "_id",
            selectField: "image",
            projection: {
              path: true,
            },
          },
        ],
        limit: 1,
      });
      detailsRes = detailsRes.data.list[0];

      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, detailsRes],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [detailsRes, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) => (item._id !== editData._id ? item : detailsRes)),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal title={title} show={show} modalSize="xxl" setShow={setShow} onClose={closeModal} disabled={disabled} onSubmit={onSubmit}>
      <div className="w-full flex ">
        <div className="flex justify-center flex-col items-center mr-10">
          {image && (
            <div className="relative w-52">
              <div className="cursor-pointer bg-slate-100 text-teal-500 w-6 h-6 flex justify-center items-center absolute rounded-full shadow -top-2 -right-2" onClick={() => setImage(null)}>
                x
              </div>
              <div className="h-52 w-52 rounded-md mb-2 overflow-hidden">
                <Image src={image instanceof File ? URL.createObjectURL(image) : image} alt={name} />
              </div>
            </div>
          )}
          <ButtonFile functions={onImageChange} buttonLabel="Upload Image" buttonIconPosition="left" buttonIcon="fa-arrow-up-from-bracket" />
        </div>


        <div className="relative w-full">
          <div className="mb-3">
            <Input label={"name"} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={"Enter name"} inputValue={name} setInput={setName} />
          </div>
          <div className="mb-3">
            <Textarea label={" Description"} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={"Enter  Description"} inputValue={description} setTextarea={setDescription} />
          </div>
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditCourseGuide;
