import SidebarMenuBlocks from '../components/navigation/SidebarMenuBlocks';
import Logo from '../assets/logo.png'
import { connect } from 'react-redux';
import Image from '../components/elements/Image';
import { useEffect, useState } from 'react';
import { postData } from '../services/api';
import { API_URL } from "../config/host";
import logoImage from "../assets/logo.png"
import { useSelector } from 'react-redux';


const DefaultSidebar = ({ isMenuOpen, setIsMenuOpen, siteState }) => {
  const favIconimg = useSelector((state) => state.siteLogo.favIcon);

  const [logo, setLogo] = useState(null)
  const [favicon, setFavIcon] = useState(null)

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link)
      ;
  }
  link.href = (favIconimg instanceof File) ? URL.createObjectURL(favIconimg) : favicon;


  useEffect(() => {
    const loadData = async () => {
      const result = await postData('site-setting/details', {})
      if (result.status === 200) {
        setLogo(result.data.logo)
        setFavIcon(result.data.favicon)
      }
    }
    loadData()
  }, [favicon, logo])

  const sidebarMenu = [
    {
      _id: '1',
      menuTitle: 'User Managemant',
      menuData: [
        {
          _id: '1-1',
          menuLabel: 'Admin',
          menuLink: '/admin',
          menuIcon: 'fa-user-crown',
          menuIconColor: 'text-rose-500',
        },
        {
          _id: '1-2',
          menuLabel: 'Companies',
          menuLink: '/companies',
          menuIcon: 'fa-city',
          menuIconColor: 'text-sky-500',
        },
        {
          _id: '1-3',
          menuLabel: 'Users',
          menuLink: '/users',
          menuIcon: 'fa-user',
          menuIconColor: 'text-yellow-500',
        },
        {
          _id: '1-4',
          menuLabel: 'Activities',
          menuLink: '/activity',
          menuIcon: 'fa-wave-pulse',
          menuIconColor: 'text-fuchsia-500',
        },
      ],
    },
    {
      _id: '4',
      menuTitle: 'Course Managemant',
      menuData: [
        {
          _id: '4-1',
          menuLabel: 'Instructors',
          menuLink: '/instructors',
          menuIcon: 'fa-chalkboard-user',
          menuIconColor: 'text-lime-500',
        },
        {
          _id: '4-2',
          menuLabel: 'Courses',
          menuLink: '/courses',
          menuIcon: 'fa-book',
          menuIconColor: 'text-violet-400',
        },
        {
          _id: '4-3',
          menuLabel: 'Quizzes',
          menuLink: '/quizzes',
          menuIcon: 'fa-puzzle-piece',
          menuIconColor: 'text-amber-500',
        },
        {
          _id: '4-4',
          menuLabel: 'Quiz Results',
          menuLink: '/quiz-results',
          menuIcon: 'fa-trophy',
          menuIconColor: 'text-fuchsia-500',
        },
        // {
        //   _id: '4-5',
        //   menuLabel: 'Course Guides',
        //   menuLink: '/course-guides',
        //   menuIcon: '<fa-regular fa-square-g',
        //   menuIconColor: 'text-cyan-500',
        // },
      ],
    },

    // {
    //   _id: '2',
    //   menuTitle: 'Category Managemant',
    //   menuData: [
    //     {
    //       _id: '2-1',
    //       menuLabel: 'Categories',
    //       menuLink: '/categories',
    //       menuIcon: 'fa-list',
    //       menuIconColor: 'text-pink-500',
    //     },
    //     {
    //       _id: '2-2',
    //       menuLabel: 'Tags',
    //       menuLink: '/tags',
    //       menuIcon: 'fa-tags',
    //       menuIconColor: 'text-violet-500',
    //     },
    //   ],
    // },    
    {
      _id: '3',
      menuTitle: 'CMS',
      menuData: [
        {
          _id: '4-6',
          menuLabel: 'Pages',
          menuLink: '/pages',
          menuIcon: 'fa-memo',
          menuIconColor: 'text-emerald-500',
        },
        {
          _id: '3-0',
          menuLabel: 'Banner',
          menuLink: '/banner',
          menuIcon: 'fa-images',
          menuIconColor: 'text-rose-500',
        },
        // {
        //   _id: '3-1',
        //   menuLabel: 'Enquiries',
        //   menuLink: '/enquiry',
        //   menuIcon: 'fa-comment-question',
        //   menuIconColor: 'text-sky-500',
        // },
        // {
        //   _id: '3-1',
        //   menuLabel: 'Testimonials',
        //   menuLink: '/testimonials',
        //   menuIcon: 'fa-square-quote',
        //   menuIconColor: 'text-yellow-500',
        // },
        {
          _id: '3-2',
          menuLabel: 'Email Template',
          menuLink: '/email-templates',
          menuIcon: 'fa-envelope',
          menuIconColor: 'text-lime-500',
        },
        {
          _id: '3-3',
          menuLabel: 'Settings',
          menuLink: '/site-settings',
          menuIcon: 'fa-gear',
          menuIconColor: 'text-violet-400',
        },
      ],
    },
  ];

  return (
    <>
      <div className='relative w-full bg-slate-900 divide-y divide-slate-800'>
        <div
          className={
            'relative flex items-center gap-4 h-30 transition-all duration-100 ' +
            (isMenuOpen ? 'px-3' : 'px-4')
          }
        >
          <div className='w-36 h-14'>
            <div className=''>
              <Image
                src={logoImage}
                alt={'Admin'}
                width={'200px'}
                height={'30px'}
                effect={'blur'}
                classes={'object-contain my-3'}
              // id={'headerImg'}
              />
              {/* {
              siteState.siteLogo === null ?
              <Image
                src={logoImage}
                alt={'Admin'}
                width={'200px'}
                height={'30px'}
                effect={'blur'}
                classes={'object-contain my-3'}
                id={'headerImg'}
              />
              :
              <Image
                src={logoImage}
                alt={'Admin'}
                width={'100%'}
                height={'100%'}
                effect={'blur'}
                classes={'object-cover'}
                id={'headerImg'}
              />
            } */}
            </div>
            {!isMenuOpen && (
              <div className='w-full mb-2'>
                {/* <div className='text-lg text-white font-medium leading-tight'>
                Radisol
              </div>  */}

              </div>
            )}
          </div>

        </div>
        <div
          className={
            'relative overflow-auto scroll-smooth scrollbar scrollbar-1 h-[calc(100vh-4rem)] transition-all duration-100 ' +
            (isMenuOpen ? 'divide-y divide-slate-800' : '')
          }
        >
          {sidebarMenu.map((item, index) => (
            <SidebarMenuBlocks
              key={index}
              menuTitle={item.menuTitle}
              menuData={item.menuData}
              isMenuOpen={isMenuOpen}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteState: state.siteLogo
  }
}

export default connect(mapStateToProps)(DefaultSidebar);
