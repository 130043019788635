import { Link } from 'react-router-dom';
import Button from '../form/Button';

const TabContainer = ({
  tabs = [],
  setActive = () => {},
  active = '',
  changeTab = () => {},
  className = '',
}) => {
  return (
    <ul
      className={
        'w-full flex flex-row list-none pl-0 mb-4 border-b border-slate-200 space-x-6 overflow-x-auto overflow-y-hidden scroll-smooth scrollbar' +
        className
      }
      id='tabs-tab'
      role='tablist'
    >
      {tabs.map((item, index) => (
        <li
          role='presentation'
          key={index}
          onClick={() => setActive(item.label)}
        >
          {item.type === 'link' ? (
            <Link
              to={item.link}
              className={
                "nav-link flex items-center justify-center font-semibold text-xs leading-tight uppercase h-10 text-slate-400 border-b-2 border-transparent whitespace-nowrap " +
                (item.current ? "!border-teal-500 !text-teal-500" : "")
              }
              id='tabs-home-tab'
              data-bs-toggle='pill'
              data-bs-target='#tabs-home'
              role='tab'
              aria-controls='tabs-home'
              aria-selected='true'
            >
              {item?.label}
            </Link>
          ) : (
            <Button
              buttonLabel={item?.label}
              buttonClasses={`!w-full !flex !items-center !justify-start !py-2 !px-3 !gap-3 !rounded-none !border-teal-500 !text-lg !font-medium !bg-transparent hover:!bg-transparent hover:!text-teal-500 !transition-all !duration-200 ${
                (active === item?.label.toLowerCase() || active === item.label)?'!bg-transparent !text-teal-500 !border-b':'!text-slate-400'
              }`}
              buttonType={'button'}
              buttonIcon={'fa-regular ' + item?.icon}
              buttonIconPosition={'left'}
              buttonHasLink={false}
              buttonLabelClasses={'!text-sm !font-semibold'}
              functions={() => changeTab(item?.code)}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default TabContainer;
