import Button from '../components/form/Button';

const AddEditLayout = ({
  children,
  title,
  submitting = false,
  disabled = false,
  onSubmit = () => {},
}) => {
  return (
    <>
      <div className='relative d-flex justify-between items-center mb-4'>
        <div className='text-3xl text-slate-800 font-bold'>{title}</div>
      </div>
      <div className='relative grid grid-cols-2 gap-4'>
        <div className='col-span-2'>
          <div className='relative bg-white rounded shadow divide-y divide-slate-200'>
            <div className='py-8 px-6 flex flex-col'>
              <div className='space-y-4 mt-4'>
                <div className='flex items-start gap-4'>{children}</div>
              </div>
            </div>
            <div className='py-3 px-3 border-t border-slate-200'>
              <div className='flex items-center justify-end gap-4'>
                <Button
                  buttonClasses=''
                  buttonLabelClasses=''
                  buttonType={'button'}
                  buttonIcon={
                    submitting ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-check'
                  }
                  buttonIconPosition={'left'}
                  buttonLabel={'Submit'}
                  functions={onSubmit}
                  buttonHasLink={false}
                  buttonDisabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditLayout;
