import ReactPaginate from 'react-paginate';
import Button from '../components/form/Button';
import Select from '../components/form/Select';
import Search from '../components/form/Search';
import { useEffect, useState } from 'react';

const ListLayout = ({ children, title, totalCount = 0, showAddButton = true, setOffset = () => { }, isSearchComponent = true, ...props }) => {


  return (
    <>
      <div className='relative flex justify-between items-center mb-4'>
        <div className='text-3xl text-slate-800 font-bold'>{title}</div>
        <div className=''>
          {showAddButton && <Button
            buttonType={'button'}
            buttonIcon={'fa-light fa-plus'}
            buttonIconPosition={'left'}
            buttonLabel={props.addName}
            buttonHasLink={props.addLink ? true : false}
            buttonLink={props.addLink || ''}
            functions={props.onAdd || (() => { })}
          />}
        </div>
      </div>
      <div className='relative flex justify-between items-center mb-4'>
        <div className='relative'>
          <Select
            xPlacement={'left'}
            dropdownButtonClass={'!bg-white'}
            selectedValue={props.limit}
            dropdownData={[
              {
                _id: totalCount || 1000000,
                label: 'All Items',
                value: totalCount || 1000000,
              },
              ...[
                { _id: 5, label: '5 Items', value: 5 },
                { _id: 10, label: '10 Items', value: 10 },
                { _id: 20, label: '20 Items', value: 20 },
                { _id: 30, label: '30 Items', value: 30 },
                { _id: 50, label: '50 Items', value: 50 },
                { _id: 100, label: '100 Items', value: 100 },
              ],
            ]}
            // getSelectedValue={(e) => {props.setLimit(e.value); setOffset(0)}}
            getSelectedValue={(e) => {
              props.setKeyword('');
              props.setLimit(e.value);
              setOffset(0);
            }}
          />
        </div>
        <div className='relative ml-auto flex justify-end items-center gap-3'>
          <div className='relative'>
            {
              isSearchComponent &&
              <Search placeholder={'Search'} value={props.keyword} setInput={props.setKeyword} />
            }
          </div>
          {props.extraSelects || ''}
        </div>
      </div>
      <div className='w-full bg-white rounded-md shadow overflow-hidden'>
        {children}
      </div>
      {props.noOfPage > 1 && (
        <div className='flex justify-end my-4'>
          <ReactPaginate
            breakLabel='...'
            breakClassName='w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200'
            nextLabel={<i className='fa-regular fa-fw fa-chevron-right'></i>}
            nextClassName='w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200'
            onPageChange={props.onPageChange || (() => { })}
            pageRangeDisplayed={3}
            pageCount={props.noOfPage}
            containerClassName='flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2'
            activeClassName='hover:bg-teal-500 bg-teal-500 text-white'
            pageLinkClassName='flex justify-center items-center w-full h-full'
            previousLabel={<i className='fa-regular fa-fw fa-chevron-left'></i>}
            pageClassName='w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200'
            previousClassName='w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200'
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
};

export default ListLayout;
